import { useContext, useState } from "react";
import { CityConfigContext, WarehouseContext } from "..";
import { RULE_CONFIG } from "../RULES";
import { InstaBuildingCooldown } from "./InstaBuildingCooldown";
import { InstaBuildTokenButton } from "./InstaBuildTokenButton";
import { ConfirmModal } from "./modals/ConfirmModal";
import { HelperFunctions } from "../HelperFunctions";

export function AllBuildingOptions({
  buildingTimers,
  bonuses,
  hasEnoughResources,
  build,
  buildingName,
  nextBuildingLevel,
  canInstaBuild,
  wheat_cost,
  wood_cost,
  stone_cost,
  iron_cost,
}) {
  const { insta_builds_left, cityProductionStats } =
    useContext(CityConfigContext);
  const { warehouse } = useContext(WarehouseContext);

  const [showModal, setShowModal] = useState(false);

  return (
    <div>
      <div>
        <div className="flex space-x-4">
          {!hasEnoughResources && cityProductionStats ? (
            <h4 className="text-red-500 font-bold">
              Not enough resources. Can afford at{" "}
              {HelperFunctions.getCanBuildTimestamp(
                warehouse.wheat,
                warehouse.wood,
                warehouse.stone,
                warehouse.iron,
                cityProductionStats.wheat_per_min,
                cityProductionStats.wood_per_min,
                cityProductionStats.stone_per_min,
                cityProductionStats.iron_per_min,
                wheat_cost,
                wood_cost,
                stone_cost,
                iron_cost,
                cityProductionStats.warehouse_capacity
              )}
            </h4>
          ) : (
            <div>
              {buildingTimers.length <
              RULE_CONFIG.default_buildings_in_queue +
                bonuses?.extra_queue_buildings ? (
                <button
                  onClick={() => build(buildingName, nextBuildingLevel, false)}
                  className="bg-green-600 hover:bg-green-500 text-white font-semibold py-2 px-4 rounded transition duration-200"
                >
                  Build
                </button>
              ) : (
                <h4 className="font-bold">Building queue full!</h4>
              )}
              {canInstaBuild && (
                <>
                  <InstaBuildingCooldown
                    build={() =>
                      build(buildingName, nextBuildingLevel, "normal")
                    }
                    builds_left={insta_builds_left}
                  />
                  <InstaBuildTokenButton build={() => setShowModal(true)} />
                </>
              )}
            </div>
          )}
        </div>
        {bonuses?.insta_building_complete_chance > 0 && (
          <h4>
            Roll lower than {bonuses.insta_building_complete_chance} to insta
            build. Does not roll for building already in queue.
          </h4>
        )}

        {showModal && (
          <ConfirmModal
            onConfirm={() => build(buildingName, nextBuildingLevel, "token")}
            onCancel={() => setShowModal(false)}
            customText={"This will cost you 1 insta upgrade token."}
          />
        )}
      </div>
    </div>
  );
}
