import { ConfirmResourcesModal } from "../modals/ConfirmResourcesModal";
import { useContext, useEffect, useState } from "react";
import { CityConfigContext, UserContext, WarehouseContext } from "../../index";
import { RULE_CONFIG } from "../../RULES.js";
import { timerActions } from "../reducers/timerReducer";
import { useTimerDispatch } from "../contexts/timerContext";

export function SendResFromMapCard(props) {
  const { warehouse } = useContext(WarehouseContext);
  const { inCityId, tradersCount } = useContext(CityConfigContext);
  const dispatch = useTimerDispatch();
  const [statusText, setStatusText] = useState("");
  const [wheat, setWheat] = useState(0);
  const [wood, setWood] = useState(0);
  const [stone, setStone] = useState(0);
  const [iron, setIron] = useState(0);
  const [jade, setJade] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [canCarry, setCanCarry] = useState(0);
  const { setShowSpinner } = useContext(UserContext);
  function setResource(setter, value, resourceType) {
    if (value <= warehouse[resourceType]) {
      // Check if the warehouse has enough resources
      setter(Number(value));
    } else {
      setStatusText(`You don't have enough ${resourceType}!`);
    }
  }

  function calculateCanCarry() {
    if (tradersCount.canCarryTotal) {
      const totalSelected = +wheat + +wood + +stone + +iron + +jade;
      const sum = tradersCount.canCarryTotal - totalSelected;
      setCanCarry(sum);
    } else {
      setCanCarry(0);
    }
  }

  function openConfirmationModal() {
    setIsModalOpen(true);
  }

  async function confirmSendResources() {
    setIsModalOpen(false);
    setStatusText("");
    setShowSpinner(true);

    if (wheat === 0 && wood === 0 && stone === 0 && iron === 0 && jade === 0) {
      setStatusText("You need to choose some resources");
      setShowSpinner(false);
      return;
    }

    // Check if warehouse has enough resources
    if (
      wheat > warehouse["wheat"] ||
      wood > warehouse["wood"] ||
      stone > warehouse["stone"] ||
      iron > warehouse["iron"] ||
      jade > warehouse["jade"]
    ) {
      setStatusText("Not enough resources in the warehouse!");
      setShowSpinner(false);
      return;
    }
    const totalSum = wheat + stone + wood + iron + jade;
    const tradersNeeded = Math.ceil(totalSum / RULE_CONFIG.merchantCarryAmount);

    if (tradersCount.tradersInCity < tradersNeeded) {
      setStatusText("You do not have enough traders for that");
      setShowSpinner(false);
      return;
    }

    const selectedCity = {
      id: props.toCityId,
    };

    const res = await fetch(
      `${import.meta.env.VITE_GAMEHOST}/api/marketplace/send/res`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },

        body: JSON.stringify({
          wheat: wheat,
          wood: wood,
          stone: stone,
          iron: iron,
          jade: jade,
          cityId: inCityId,
          selectedCity: selectedCity,
        }),
        credentials: "include",
      }
    );
    if (res.status === 200) {
      const data = await res.json();

      dispatch({
        type: timerActions.ADD_TIMER,
        category: "sendResourcesTimers",
        payload: {
          duration: data.travelTimeMillies,
          timeLeft: data.timeLeft,
          endTime: data.arrival_time,
          queueId: data.itemQueueId,
          extra: {
            toCity: data.toCity,
            toCityName: data.toCityName,
            fromCity: data.fromCity,
            fromCityName: data.fromCityName,
            toUserName: data.toUserName,
            fromUserName: data.fromUserName,
            wheat: data.wheat,
            wood: data.wood,
            stone: data.stone,
            iron: data.iron,
            jade: data.jade,
            tradersNeeded: data.tradersNeeded,
          },
        },
      });
    }

    setTimeout(() => {
      setShowSpinner(false);
    }, 1700);
  }
  useEffect(() => {
    calculateCanCarry();
  }, [tradersCount, wheat, wood, stone, iron, jade]);

  return (
    <div className="flex flex-col justify-center mt-4 bg-mainAttack p-2 rounded ">
      <div>
        <div className="text-center">Wheat</div>
        <div className="flex justify-center">
          <input
            className="w-[50%] pr-2 bg-gray-800"
            type="number"
            max={warehouse["wheat"]}
            onChange={(e) => setResource(setWheat, e.target.value, "wheat")}
          />
        </div>
      </div>
      <div>
        <div className="text-center">Wood </div>
        <div className="flex justify-center">
          <input
            className="w-[50%] pr-2 bg-gray-800"
            type="number"
            max={warehouse["wood"]}
            onChange={(e) => setResource(setWood, e.target.value, "wood")}
          />
        </div>
      </div>
      <div>
        <div className="text-center">Stone </div>
        <div className="flex justify-center">
          <input
            className="w-[50%] pr-2 bg-gray-800"
            type="number"
            max={warehouse["stone"]}
            onChange={(e) => setResource(setStone, e.target.value, "stone")}
          />
        </div>
      </div>
      <div>
        <div className="text-center">Iron </div>
        <div className="flex justify-center">
          <input
            className="w-[50%] pr-2 bg-gray-800"
            type="number"
            max={warehouse["iron"]}
            onChange={(e) => setResource(setIron, e.target.value, "iron")}
          />
        </div>
      </div>
      <div>
        <div className="text-center">Jade </div>
        <div className="flex justify-center">
          <input
            className="w-[50%] pr-2 bg-gray-800"
            type="number"
            max={warehouse["jade"]}
            onChange={(e) => setResource(setJade, e.target.value, "jade")}
          />
        </div>
      </div>

      <div className="flex justify-center">
        <button
          className="text-sm bg-mainReturn mt-4"
          onClick={openConfirmationModal}
        >
          Send resources
        </button>
      </div>

      {isModalOpen && (
        <ConfirmResourcesModal
          traders={tradersCount.maxTraders}
          wheat={wheat}
          wood={wood}
          stone={stone}
          iron={iron}
          jade={jade}
          confirm={confirmSendResources}
          closeModal={() => setIsModalOpen(false)}
        />
      )}
      <div className="text-center">
        <div>
          Traders available: {tradersCount.tradersInCity}/
          {tradersCount.maxTraders}
        </div>
        <div>Can carry: {canCarry} resources</div>
      </div>
    </div>
  );
}
