import { MapAttackCard } from "../cards/MapAttackCard";
import { useContext, useState } from "react";
import { CityConfigContext } from "../../index";
import { Link } from "react-router-dom";
import { StyledUser } from "../StyledUser";

export function CityProfileModal(props) {
  const { cityRestrictions } = useContext(CityConfigContext);
  const [showAttackMenu, setShowAttackMenu] = useState(false);
  const [attackAction, setAttackAction] = useState();

  function hideAttackMenu() {
    setShowAttackMenu(false);
  }

  function displayAttackMenu(troopAction) {
    setAttackAction(troopAction);
    setShowAttackMenu(true);
  }

  if (!showAttackMenu) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50 text-gray-50">
        {/* Modal backdrop */}
        <div className="absolute inset-0" onClick={props.closeModal}></div>

        {/* Modal container */}
        <div className="relative  bg-mainLightBrown4 rounded-2xl shadow-lg w-full max-w-lg mx-4 sm:mx-6 lg:mx-auto p-6 max-h-screen overflow-auto">
          {/* Close button */}
          <button
            className="absolute top-4 right-4 text-white hover:text-gray-600 text-xs focus:outline-none p-2"
            onClick={props.closeModal}
            aria-label="Close"
          >
            &times;
          </button>

          {/* Modal header */}
          <div className="text-center mb-2">
            <h3 className="text-xl font-bold text-white">{props.cityName}</h3>
            <p className=" text-sm text-gray-800">City Overview</p>
          </div>

          {/* Modal content */}
          <div className="bg-mainLightGreen2 rounded-lg p-4">
            <div className="grid grid-cols-2 gap-4">
              {/* Left Section */}
              <div>
                <p className="text-gray-600 flex ">
                  <span className="font-medium">User:</span>{" "}
                  <Link
                    className="text-blue-600 font-semibold hover:underline ml-2"
                    to={`/profile/${props.username}`}
                  >
                    <StyledUser
                      username={props.username}
                      nameColor={props.nameColor}
                      nameIcon={props.nameIcon}
                      nameTitle={props.nameTitle}
                    />
                  </Link>
                </p>
                <p className="text-gray-600 mt-2">
                  <span className="font-medium">Population:</span>{" "}
                  {props.cityPop}
                </p>
              </div>

              {/* Right Section */}
              <div className="text-center">
                <p className="text-gray-600">
                  <span className="font-medium">Coordinates:</span> (X:{" "}
                  {props.xCoord}, Y: {props.yCoord})
                </p>
                {props.isMyCity ? (
                  <div className="mt-3 text-green-600 font-semibold">
                    This is your city!
                  </div>
                ) : (
                  <div className="mt-3">
                    {cityRestrictions.hasBarracks ? (
                      <div>
                        <button
                          className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow hover:bg-blue-600 focus:outline-none text-sm sm:text-base"
                          onClick={() => displayAttackMenu("attack")}
                        >
                          Attack City
                        </button>
                        <button
                          className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow hover:bg-blue-600 focus:outline-none text-sm sm:text-base mt-1"
                          onClick={() => displayAttackMenu("reinforce")}
                        >
                          Reinforce
                        </button>
                      </div>
                    ) : (
                      <div className="text-red-500 mt-2">
                        You need a barracks to attack!
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="fixed bottom-0 left-0 w-full h-full grid place-items-center overflow-auto">
      <div
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        onClick={props.closeModal}
      >
        {" "}
      </div>
      <div className="relative bg-mainLightBrown4 p-4 rounded-lg">
        <div className="bg-mainLightGreen2 p-2">
          <button
            className="absolute top-0 right-0 mt-2 mr-2 text-white font-bold text-xxs sm:text-xs focus:outline-none"
            onClick={props.closeModal}
          >
            &times;
          </button>
          <div className="grid place-items-center">
            <MapAttackCard
              hideMenu={hideAttackMenu}
              cityId={props.cityId}
              targetCity={props.cityName}
              targetUser={props.username}
              targetUserColor={props.nameColor}
              targetUserIcon={props.nameIcon}
              targetUserTitle={props.nameTitle}
              actionMode={attackAction}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
