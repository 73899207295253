export const CONSTANTS = {
  GAME_NAME: "Siege",
  CURRENT_ENDGAME_NPC: "Emperor Huan",
  LAST_ROUND_STARTED: "20.08.2024",
  SUPPORT_EMAIL: "siege.game.help@gmail.com",
  RESOURCE1: "Wheat",
  RESOURCE2: "Wood",
  RESOURCE3: "Stone",
  RESOURCE4: "Iron",
  TRIBE_ONE_TROOP_ONE: "Clubmen",
  TRIBE_ONE_TROOP_ONE_IMAGE: "images/troops/ClubmanIcon.png",
  TRIBE_ONE_TROOP_TWO: "Spearmen",
  TRIBE_ONE_TROOP_TWO_IMAGE:
    "https://w7.pngwing.com/pngs/785/29/png-transparent-one-piece-treasure-cruise-spearman-s-rank-correlation-coefficient-sabo-grand-line-one-piece.png",
  TRIBE_ONE_TROOP_THREE: "Axemen",
  TRIBE_ONE_TROOP_THREE_IMAGE:
    "https://cdn1.iconfinder.com/data/icons/lumberjack-and-woodcutter/595/Lumberjack-05-512.png",
  TRIBE_ONE_TROOP_FOUR: "Founder",
  TRIBE_ONE_TROOP_FOUR_IMAGE:
    "https://i.etsystatic.com/17987356/r/il/af5a2a/3888920351/il_fullxfull.3888920351_mkyi.jpg",
  TRIBE_ONE_TROOP_FIVE: "Guardian",
  TRIBE_ONE_TROOP_FIVE_IMAGE:
    "https://i.etsystatic.com/17987356/r/il/af5a2a/3888920351/il_fullxfull.3888920351_mkyi.jpg",
  TRIBE_ONE_TROOP_SIX: "Warlord",
  TRIBE_ONE_TROOP_SIX_IMAGE:
    "https://i.etsystatic.com/17987356/r/il/af5a2a/3888920351/il_fullxfull.3888920351_mkyi.jpg",
  TRIBE_ONE_TROOP_SEVEN: "Scout",
  TRIBE_ONE_TROOP_EIGHT: "Catapult",
  TRIBE_ONE_TROOP_NINE: "Conquerer",
  CITY_LIST_BG_IMAGE:
    "https://img.freepik.com/free-vector/purple-halftone-dots-background_1164-1084.jpg",
};

export const CITY_BUILDING_DESCRIPTIONS = {
  Warehouse:
    "Secure and store your valuable resources in the Warehouse, ensuring a big stockpile of materials to fuel your kingdom's growth and expansion.",
  Barracks:
    "Train and prepare your soldiers in the Barracks, transforming recruits into disciplined warriors ready to defend your kingdom and conquer new territories. Gain access to defensive Spearmen at level 3, and the overall beast of a man, the Axemen at lvl 6. Higher level barracks trains troops even faster!",
  Mapchamber:
    "Strategize and train your founders in the Mapchamber, gain the knowledge and skills needed to expand your empire. Every 5 levels grants access to new founders. You need 3 founders to make a new city.",
  Casino:
    "Try your luck in the Casino, play blackjack, spin the slots, or open loot chests to win coins. Trade your winnings for valuable resources to boost your kingdom's growth. Leveling up the casino gives you +2% dropchance of lootchest per level",
  Marketplace:
    "This is where you can exchange resources with fellow players. Manage your trades wisely to ensure your kingdom grows!",
  Embassy:
    "Forge alliances and strengthen bonds at the Embassy. Here, you can create powerful alliances or join existing ones. Strengthen your kingdom through teaming up with fellow players. Level 5 to make an alliance, and level it higher to increase max members.",
  Brothel:
    "Boost your city's productivity with the Brothel, where the morale of your workers is enhanced, leading to increased building speeds and faster development.",
  Mill: "Increase your wheat production with the Mill. By refining your farming techniques, the Mill boosts your wheat yield per minute, ensuring a steady supply of food for your growing kingdom.",
  Lumberyard:
    "Boost your wood production with the Lumberyard. By optimizing your logging operations, the Lumberyard increases your wood yield per minute, ensuring a constant supply of timber for your kingdom's needs.",
  Quarry:
    "Boost your stone production with the Quarry. By improving your extraction techniques, the Quarry increases your stone yield per minute, ensuring a steady supply of materials for your kingdom's construction projects.",
  Smith:
    "Increase your iron production with the Smith. By refining your smelting processes, the Smith boosts your iron yield per minute, ensuring a continuous supply of metal for your kingdom's advancements.",
  Wall: "Increase your cities defence power! Every level of the wall increases the multiplier gained from your defence power in the skill tree.",
  Church:
    "Gain access to the cities skill trees. Do different activities to gain xp for skill points. Only one of the trees last skills can be achieved per city.",
  CaligulasPalace: "Palace description V2",
  Foundry:
    "Make some use of all that iron and craft some catapults! Crush the enemies cities to the ground. Only the strongest shall survive!",
  VictoryMonument: "Victory monument description placeholder.",
};
export const BUILDING_MENU_DESCRPIPTIONS = {
  Warehouse: "Increase your cities maximum resource capacity.",
  Barracks: "Train troops to attack and defend yourself against other players.",
  Mapchamber: "Make founders and start planning your next cities.",
  Embassy: "Team up with other players.",
  Casino:
    "Buy coins and fill up your warehouse quick! it will be quick, right?",
  Marketplace: "Buy, sell and send resources to other cities.",
  Mill: "Increase your wheat per minute%.",
  Lumberyard: "Increase your wood per minute%.",
  Quarry: "Increase your stone per minute%.",
  Smith: "Increase your iron per minute%.",
  Brothel:
    "Decrease the building time your workers use to build and upgrade structures.",
  Church:
    "Gain access to the cities skill tree. Level up the church for more skill points.",
  CaligulasPalace: "Palace description",
  Foundry: "Make catapults to destroy your enemies!",
  VictoryMonument: "Reach level 50 to win the game!",
};

export const RESOURCE_FIELD_DESCRIPTIONS = {
  Wheat:
    "Harvest wheat from your fertile fields, ensuring a steady supply of food to sustain your growing population and keep your armies well-fed for upcoming battles.",
  Wood: "Harvest the finest lumber from the surrounding dense forests, providing the raw materials necessary for constructing buildings, training troops, and expanding your kingdom.",
  Stone:
    "Deep within the rocky hills of your domain lies the Stone Mine, a crucial source of sturdy stone for your empire.",
  Iron: "Transform raw ore into refined iron, essential for forging weapons, armoring troops, and advancing your kingdom's technological prowess.",
};
import Warehouse from "./images/buildings/Warehouse.png";
import Barracks from "./images/buildings/Barracks.png";
import Clubman from "./images/guide/ClubmanIcon.png";
import Spearman from "./images/guide/SpearmanIcon.png";
import Axeman from "./images/guide/AxemanIcon.png";
import Founder from "./images/guide/FounderIcon.png";
import Conquerer from "./images/guide/ConquererIcon.png";
import Warlord from "./images/guide/WarlordIcon.png";
import Scout from "./images/guide/ScoutIcon.png";
import Catapult from "./images/guide/CatapultIcon.png";
import Guardian from "./images/guide/GuardianIcon.png";
import Lumberyard from "./images/buildings/Lumberyard.png";
import Mill from "./images/buildings/Mill.png";
import Blacksmith from "./images/buildings/Blacksmith.png";
import Quarry from "./images/buildings/Quarry.png";
import Casino from "./images/buildings/Casino.png";
import Brothel from "./images/buildings/Brothel.png";
import Embassy from "./images/buildings/Embassy.png";
import Mapchamber from "./images/buildings/MapChamber.png";
import Marketplace from "./images/buildings/Marketplace.png";
import Wall from "./images/buildings/Embassy.png";
import VictoryMonument from "./images/buildings/VictoryMonument.png";
import FarmerIcon from "./images/guide/FarmerStrategy.png";
import FighterIcon from "./images/guide/FighterStrategy.png";
import GamblerIcon from "./images/guide/GamblerStrategy.png";
import ArtifexFoundryIcon from "./images/buildings/Foundry.png";
import ChurchIcon from "./images/buildings/Church_.png";
import CaligulasPalaceIcon from "./images/buildings/Caligula'sPalace.png";
import WheatIcon from "./images/resources/WheatIcon.png";
import WoodIcon from "./images/resources/WoodIcon.png";
import StoneIcon from "./images/resources/StoneIcon.png";
import IronIcon from "./images/resources/IronIcon.png";

export const BUILDINGS_AND_PRICES = {
  Clubman: {
    image: Clubman,
    description:
      "Attack power: 15, Defence power: 5, Movement speed: 5, Resource capacity: 150. The Club Man is a basic infantry unit, armed with a sturdy wooden club. These warriors are the backbone of your early military forces, providing reliable strength in numbers. While not heavily armored, Club Men are quick to train and cost-effective, making them an essential part of your initial army. Use them to raid enemy villages, defend your territory, and support more advanced troops in battle.",

    levels: [
      {
        level: 1,
        wheat: 230,
        wood: 220,
        stone: 190,
        iron: 170,
        description: "Clubman, the cheapest troop.",
        seconds_to_build: 50,
        attack_power: 15,
        defence_power: 5,
        movement_speed: 5,
        resource_capacity: 150,
      },
    ],
  },
  Spearman: {
    image: Spearman,
    description:
      "Attack power: 10, Defence power: 15, Movement speed: 5. Resource capacity: 150. The Spearman is a versatile infantry unit equipped with a long spear, perfect for both offensive and defensive roles. Trained to hold the line against enemy charges and to engage foes from a distance, Spearmen are essential for maintaining the integrity of your battle formations. They offer a balance of reach and strength, making them effective against a variety of enemy units. Deploy Spearmen to protect your settlements, support your attacks, and bolster your army's defenses. Can be trained with a barracks lvl 4.",
    levels: [
      {
        level: 1,
        wheat: 260,
        wood: 250,
        stone: 220,
        iron: 210,
        seconds_to_build: 80,
        attack_power: 10,
        defence_power: 28,
        movement_speed: 5,
        resource_capacity: 100,
      },
    ],
  },
  Axeman: {
    image: Axeman,
    description:
      "Attack power: 30, Defence power: 20, Movement speed: 5, Resource capacity: 350. The Axemen are formidable infantry units wielding heavy battle axes. Known for their powerful strikes and formidable melee combat skills, they excel in breaking through enemy defenses and inflicting significant damage. Their strength makes them highly effective against armored foes and fortifications. Deploy Axemen to lead assaults, smash through defenses, and turn the tide of battle in your favor. Can be trained in the barracks at lvl 10.",
    levels: [
      {
        level: 1,
        wheat: 290,
        wood: 270,
        stone: 260,
        iron: 250,
        seconds_to_build: 130,
        attack_power: 45,
        defence_power: 26,
        movement_speed: 5,
        resource_capacity: 350,
      },
    ],
  },
  Founder: {
    image: Founder,
    description:
      "Attack power: 2, Defence power: 1, Movement speed: 8, Resource capacity: 200. The Founder is a specialized unit tasked with establishing new cities and expanding your empire. Equipped with the knowledge and tools needed to lay the groundwork for new settlements, Founders are essential for strategic growth. Once trained, they venture into unclaimed territories to set up new bases of operation, extending your influence and control. Deploy Founders to secure new lands, increase your resources, and strengthen your empire's reach. It takes 3 founders to make a new city. And 3 new founders are avaialable each 5 new levels of the Mapchamber. The founder can also be used for attacking..",
    levels: [
      {
        level: 1,
        wheat: 4900,
        wood: 4800,
        stone: 4670,
        iron: 4500,
        seconds_to_build: 399,
        attack_power: 2,
        defence_power: 1,
        movement_speed: 8,
        resource_capacity: 200,
      },
    ],
  },
  Guardian: {
    image: Guardian,
    description:
      "Attack power: 35, Defence power: 55, Movement speed: 10, Resource capacity: 1100. The Guardian is the ultimate defense unit, equipped with the most advanced defensive weaponry and armor. Its powerful attacks and defensive capabilities make it a formidable force to be reckoned with. Guardians are earned through the skill tree in the church, and is the best defensive troop. ",
    levels: [
      {
        level: 1,
        wheat: 720,
        wood: 680,
        stone: 660,
        iron: 620,
        seconds_to_build: 230,
        attack_power: 40,
        defence_power: 90,
        movement_speed: 10,
        resource_capacity: 1100,
      },
    ],
  },
  Warlord: {
    image: Warlord,
    description:
      "Attack power: 55, Defence power: 35, Movement speed: 10, Resource capacity: 1100. The Warlord is a powerful unit, equipped with the most advanced offensive weaponry and armor. Its powerful attacks and offensive capabilities make it a formidable force to be reckoned with. Warlords are earned through the skill tree in the church, and is the best offensive troop. ",
    levels: [
      {
        level: 1,
        wheat: 640,
        wood: 650,
        stone: 670,
        iron: 720,
        seconds_to_build: 230,
        attack_power: 90,
        defence_power: 40,
        movement_speed: 10,
        resource_capacity: 1100,
      },
    ],
  },
  Scout: {
    image: Scout,
    description:
      "Attack power: 3, Defence power: 3, Movement speed: 12, Resource capacity: 20. The Scout is a specialized unit tasked with scouting and gathering information about enemy armies. Its stealth and stealth capabilities make it a valuable asset in any battle. Scouts are trained in the barracks at level 6. ",
    levels: [
      {
        level: 1,
        wheat: 700,
        wood: 690,
        stone: 750,
        iron: 660,
        seconds_to_build: 660,
        attack_power: 3,
        defence_power: 3,
        movement_speed: 12,
        resource_capacity: 20,
      },
    ],
  },
  Catapult: {
    image: Catapult,
    description:
      "Attack power: 20, Defence power: 5, Movement speed: 5, Resource capacity: 20. The Catapult is a specialized unit tasked with launching catapults and destroying enemy buildings. Catapults are trained in the Forgery.",
    levels: [
      {
        level: 1,
        wheat: 1500,
        wood: 1800,
        stone: 1650,
        iron: 2100,
        seconds_to_build: 150,
        attack_power: 20,
        defence_power: 5,
        movement_speed: 5,
        resource_capacity: 20,
      },
    ],
  },
  Conquerer: {
    image: Conquerer,
    description:
      "Attack power: 15, Defence power: 15, Movement speed: 8, Resource capacity: 1200. The Conquerer is a specialized unit tasked with taking over enemy cities and expanding your empire. Conquerers are trained in the mapchamber at max level.",
    levels: [
      {
        level: 1,
        wheat: 12500,
        wood: 11400,
        stone: 13700,
        iron: 15200,
        seconds_to_build: 900,
        attack_power: 15,
        defence_power: 15,
        movement_speed: 8,
        resource_capacity: 1200,
      },
    ],
  },
  Warehouse: {
    image: Warehouse,
    description:
      "The Warehouse serves as the vital storage hub for your town's crucial resources: wheat, wood, stone, and iron. This expansive facility is essential for safeguarding the materials needed to upgrade your town and build troops. Structurally robust and strategically important, the warehouse must be managed wisely to ensure a steady supply of resources.",
    levels: [
      {
        level: 1,
        wheat: 250,
        wood: 350,
        stone: 300,
        iron: 280,
        seconds_to_build: 30,
      },
      {
        level: 2,
        wheat: 350,
        wood: 490,
        stone: 420,
        iron: 392,
        seconds_to_build: 38,
      },
      {
        level: 3,
        wheat: 490,
        wood: 686,
        stone: 588,
        iron: 548,
        seconds_to_build: 48,
      },
      {
        level: 4,
        wheat: 686,
        wood: 960,
        stone: 823,
        iron: 768,
        seconds_to_build: 61,
      },
      {
        level: 5,
        wheat: 960,
        wood: 1344,
        stone: 1152,
        iron: 1075,
        seconds_to_build: 78,
      },
      {
        level: 6,
        wheat: 1344,
        wood: 1882,
        stone: 1613,
        iron: 1505,
        seconds_to_build: 99,
      },
      {
        level: 7,
        wheat: 1882,
        wood: 2635,
        stone: 2258,
        iron: 2108,
        seconds_to_build: 126,
      },
      {
        level: 8,
        wheat: 2635,
        wood: 3689,
        stone: 3162,
        iron: 2951,
        seconds_to_build: 160,
      },
      {
        level: 9,
        wheat: 3689,
        wood: 5165,
        stone: 4427,
        iron: 4132,
        seconds_to_build: 203,
      },
      {
        level: 10,
        wheat: 5165,
        wood: 7231,
        stone: 6198,
        iron: 5785,
        seconds_to_build: 258,
      },
      {
        level: 11,
        wheat: 7231,
        wood: 10123,
        stone: 8677,
        iron: 8099,
        seconds_to_build: 327,
      },
      {
        level: 12,
        wheat: 10123,
        wood: 14173,
        stone: 12148,
        iron: 11338,
        seconds_to_build: 416,
      },
      {
        level: 13,
        wheat: 14173,
        wood: 19842,
        stone: 17008,
        iron: 15874,
        seconds_to_build: 528,
      },
      {
        level: 14,
        wheat: 19842,
        wood: 27780,
        stone: 23811,
        iron: 22224,
        seconds_to_build: 671,
      },
      {
        level: 15,
        wheat: 27780,
        wood: 38892,
        stone: 33336,
        iron: 31113,
        seconds_to_build: 852,
      },
      {
        level: 16,
        wheat: 38892,
        wood: 54448,
        stone: 46670,
        iron: 43559,
        seconds_to_build: 1082,
      },
      {
        level: 17,
        wheat: 54448,
        wood: 72578,
        stone: 65338,
        iron: 60982,
        seconds_to_build: 1374,
      },
      {
        level: 18,
        wheat: 74228,
        wood: 96719,
        stone: 91474,
        iron: 85375,
        seconds_to_build: 1745,
      },
      {
        level: 19,
        wheat: 106719,
        wood: 129407,
        stone: 128063,
        iron: 119526,
        seconds_to_build: 2216,
      },
      {
        level: 20,
        wheat: 149407,
        wood: 169170,
        stone: 174289,
        iron: 167336,
        seconds_to_build: 2814,
      },
      {
        level: 21,
        wheat: 209170,
        wood: 222838,
        stone: 211004,
        iron: 234271,
        seconds_to_build: 3574,
      },
      {
        level: 22,
        wheat: 292838,
        wood: 309974,
        stone: 320406,
        iron: 317979,
        seconds_to_build: 4539,
      },
      {
        level: 23,
        wheat: 409974,
        wood: 433964,
        stone: 421969,
        iron: 419171,
        seconds_to_build: 5765,
      },
      {
        level: 24,
        wheat: 573964,
        wood: 603549,
        stone: 588757,
        iron: 602839,
        seconds_to_build: 7322,
      },
      {
        level: 25,
        wheat: 803549,
        wood: 832469,
        stone: 824259,
        iron: 819975,
        seconds_to_build: 9298,
      },
      {
        level: 26,
        wheat: 1125000,
        wood: 1070000,
        stone: 1050000,
        iron: 1060000,
        seconds_to_build: 11809,
      },
      {
        level: 27,
        wheat: 1580000,
        wood: 1410000,
        stone: 1590000,
        iron: 1570000,
        seconds_to_build: 14997,
      },
      {
        level: 28,
        wheat: 2200000,
        wood: 2080000,
        stone: 2210000,
        iron: 2170000,
        seconds_to_build: 19047,
      },
      {
        level: 29,
        wheat: 3087000,
        wood: 2920000,
        stone: 3010000,
        iron: 3060000,
        seconds_to_build: 24189,
      },
      {
        level: 30,
        wheat: 4220000,
        wood: 3990000,
        stone: 4190000,
        iron: 4140000,
        seconds_to_build: 30721,
      },
    ],
  },
  Mill: {
    image: Mill,
    description:
      "The Mill is a crucial facility for your empire's agricultural productivity, significantly boosting your wheat production. By refining the processes of milling and grain storage, the Mill increases your wheat yield per minute. This ensures a steady supply of food to sustain your population and support your armies. Efficient management of the Mill is essential for maintaining the health and growth of your kingdom.",
    levels: [
      {
        level: 1,
        wheat: 15500,
        wood: 14500,
        stone: 14200,
        iron: 13900,
        seconds_to_build: 300,
      },
      {
        level: 2,
        wheat: 24800,
        wood: 23200,
        stone: 22720,
        iron: 22240,
        seconds_to_build: 444,
      },
      {
        level: 3,
        wheat: 39680,
        wood: 37120,
        stone: 36352,
        iron: 35584,
        seconds_to_build: 657,
      },
      {
        level: 4,
        wheat: 63488,
        wood: 59392,
        stone: 58163,
        iron: 56934,
        seconds_to_build: 973,
      },
      {
        level: 5,
        wheat: 101580,
        wood: 95027,
        stone: 93061,
        iron: 91095,
        seconds_to_build: 1439,
      },
      {
        level: 6,
        wheat: 162529,
        wood: 152043,
        stone: 148897,
        iron: 145752,
        seconds_to_build: 2130,
      },
      {
        level: 7,
        wheat: 260046,
        wood: 243269,
        stone: 238236,
        iron: 233203,
        seconds_to_build: 3153,
      },
      {
        level: 8,
        wheat: 416074,
        wood: 389231,
        stone: 381178,
        iron: 373125,
        seconds_to_build: 4666,
      },
      {
        level: 9,
        wheat: 665719,
        wood: 622770,
        stone: 609885,
        iron: 597000,
        seconds_to_build: 6906,
      },
      {
        level: 10,
        wheat: 1060000,
        wood: 996432,
        stone: 975816,
        iron: 955200,
        seconds_to_build: 10221,
      },
    ],
  },
  Lumberyard: {
    image: Lumberyard,
    description:
      "The Lumberyard is an essential facility for maximizing your wood production. By optimizing your logging operations and refining the processing of timber, the Lumberyard increases your wood yield per minute. This ensures a constant supply of lumber for building structures, crafting tools, and expanding your kingdom. Effective management of the Lumberyard is key to maintaining a robust and thriving settlement.",
    levels: [
      {
        level: 1,
        wheat: 16100,
        wood: 16900,
        stone: 13800,
        iron: 12900,
        seconds_to_build: 310,
      },
      {
        level: 2,
        wheat: 25760,
        wood: 27040,
        stone: 22080,
        iron: 20640,
        seconds_to_build: 459,
      },
      {
        level: 3,
        wheat: 41216,
        wood: 43264,
        stone: 35328,
        iron: 33024,
        seconds_to_build: 679,
      },
      {
        level: 4,
        wheat: 65945,
        wood: 69222,
        stone: 56524,
        iron: 52838,
        seconds_to_build: 1005,
      },
      {
        level: 5,
        wheat: 105512,
        wood: 110755,
        stone: 90439,
        iron: 84541,
        seconds_to_build: 1487,
      },
      {
        level: 6,
        wheat: 168820,
        wood: 177209,
        stone: 144703,
        iron: 135266,
        seconds_to_build: 2201,
      },
      {
        level: 7,
        wheat: 270113,
        wood: 283534,
        stone: 231525,
        iron: 216426,
        seconds_to_build: 3258,
      },
      {
        level: 8,
        wheat: 432181,
        wood: 453655,
        stone: 370440,
        iron: 346281,
        seconds_to_build: 4822,
      },
      {
        level: 9,
        wheat: 691489,
        wood: 725849,
        stone: 592705,
        iron: 554050,
        seconds_to_build: 7136,
      },
      {
        level: 10,
        wheat: 1100000,
        wood: 1160000,
        stone: 948328,
        iron: 886481,
        seconds_to_build: 10561,
      },
    ],
  },
  Smith: {
    image: Blacksmith,
    description:
      "The Smith is a pivotal facility for enhancing your iron production. By refining your smelting processes and improving metalwork techniques, the Blacksmith boosts your iron yield per minute. This ensures a continuous supply of iron for forging weapons, crafting tools, and advancing your kingdom's infrastructure. Efficient management of the Blacksmith is crucial for maintaining a strong and technologically advanced kingdom.",
    levels: [
      {
        level: 1,
        wheat: 15600,
        wood: 14300,
        stone: 16400,
        iron: 16900,
        seconds_to_build: 340,
      },
      {
        level: 2,
        wheat: 24960,
        wood: 22880,
        stone: 26240,
        iron: 27040,
        seconds_to_build: 503,
      },
      {
        level: 3,
        wheat: 39936,
        wood: 36608,
        stone: 41984,
        iron: 43264,
        seconds_to_build: 745,
      },
      {
        level: 4,
        wheat: 63897,
        wood: 58572,
        stone: 67174,
        iron: 69222,
        seconds_to_build: 1102,
      },
      {
        level: 5,
        wheat: 102236,
        wood: 93716,
        stone: 107479,
        iron: 110755,
        seconds_to_build: 1631,
      },
      {
        level: 6,
        wheat: 163577,
        wood: 149946,
        stone: 171966,
        iron: 177209,
        seconds_to_build: 2414,
      },
      {
        level: 7,
        wheat: 261724,
        wood: 239914,
        stone: 275146,
        iron: 283534,
        seconds_to_build: 3573,
      },
      {
        level: 8,
        wheat: 418759,
        wood: 383862,
        stone: 440234,
        iron: 453655,
        seconds_to_build: 5288,
      },
      {
        level: 9,
        wheat: 670014,
        wood: 614180,
        stone: 704374,
        iron: 725849,
        seconds_to_build: 7827,
      },
      {
        level: 10,
        wheat: 1070000,
        wood: 982688,
        stone: 1130000,
        iron: 1160000,
        seconds_to_build: 11583,
      },
    ],
  },
  Quarry: {
    image: Quarry,
    description:
      "The Quarry is an essential facility for maximizing your stone production. By improving your extraction techniques and optimizing the processing of raw stone, the Quarry increases your stone yield per minute. This steady supply of stone is crucial for constructing and upgrading buildings within your kingdom. Effective management of the Quarry is vital for ensuring the continuous growth and fortification of your settlement.",
    levels: [
      {
        level: 1,
        wheat: 14100,
        wood: 15900,
        stone: 16600,
        iron: 16100,
        seconds_to_build: 280,
      },
      {
        level: 2,
        wheat: 22560,
        wood: 25440,
        stone: 26560,
        iron: 25760,
        seconds_to_build: 414,
      },
      {
        level: 3,
        wheat: 36096,
        wood: 40704,
        stone: 42496,
        iron: 41216,
        seconds_to_build: 613,
      },
      {
        level: 4,
        wheat: 57753,
        wood: 65126,
        stone: 67993,
        iron: 65945,
        seconds_to_build: 908,
      },
      {
        level: 5,
        wheat: 92405,
        wood: 104202,
        stone: 108789,
        iron: 105512,
        seconds_to_build: 1343,
      },
      {
        level: 6,
        wheat: 147849,
        wood: 166723,
        stone: 174063,
        iron: 168820,
        seconds_to_build: 1988,
      },
      {
        level: 7,
        wheat: 236558,
        wood: 266757,
        stone: 278501,
        iron: 270113,
        seconds_to_build: 2943,
      },
      {
        level: 8,
        wheat: 378493,
        wood: 426812,
        stone: 445602,
        iron: 432181,
        seconds_to_build: 4355,
      },
      {
        level: 9,
        wheat: 605590,
        wood: 682899,
        stone: 712964,
        iron: 691489,
        seconds_to_build: 6445,
      },
      {
        level: 10,
        wheat: 968944,
        wood: 1092639,
        stone: 1140743,
        iron: 1106383,
        seconds_to_build: 9539,
      },
    ],
  },
  Casino: {
    image: Casino,
    description:
      "The Casino is a lively hub where fortune favors the bold. Here, you can engage in games of chance like blackjack and slots, or open loot chests to win valuable coins. These coins can be traded for essential resources, providing a unique and exciting way to boost your kingdom's growth. Skillful and strategic play in the Casino can yield significant rewards, aiding in the expansion and prosperity of your settlement. The higher level the casino. The higher level chance of earning a loot chest. Chest are earned by using 5 coins in slots, or if you attack and kill or loose more than 10 troops, if you steal more than 1000 resources, or if you win more than 10 coins in a blackjack game at once.",
    levels: [
      {
        level: 1,
        wheat: 4200,
        wood: 4120,
        stone: 3940,
        iron: 3790,
        seconds_to_build: 120,
      },
      {
        level: 2,
        wheat: 5040,
        wood: 4944,
        stone: 4728,
        iron: 4548,
        seconds_to_build: 150,
      },
      {
        level: 3,
        wheat: 6048,
        wood: 5932,
        stone: 5673,
        iron: 5457,
        seconds_to_build: 188,
      },
      {
        level: 4,
        wheat: 7257,
        wood: 7119,
        stone: 6808,
        iron: 6549,
        seconds_to_build: 234,
      },
      {
        level: 5,
        wheat: 8709,
        wood: 8543,
        stone: 8169,
        iron: 7858,
        seconds_to_build: 293,
      },
      {
        level: 6,
        wheat: 10450,
        wood: 10251,
        stone: 9803,
        iron: 9430,
        seconds_to_build: 366,
      },
      {
        level: 7,
        wheat: 12541,
        wood: 12302,
        stone: 11764,
        iron: 11316,
        seconds_to_build: 458,
      },
      {
        level: 8,
        wheat: 15049,
        wood: 14762,
        stone: 14117,
        iron: 13580,
        seconds_to_build: 572,
      },
      {
        level: 9,
        wheat: 18059,
        wood: 17715,
        stone: 16941,
        iron: 16296,
        seconds_to_build: 715,
      },
      {
        level: 10,
        wheat: 21671,
        wood: 21258,
        stone: 20329,
        iron: 19555,
        seconds_to_build: 894,
      },
      {
        level: 11,
        wheat: 26005,
        wood: 25509,
        stone: 24395,
        iron: 23466,
        seconds_to_build: 1118,
      },
      {
        level: 12,
        wheat: 31206,
        wood: 30611,
        stone: 29274,
        iron: 28160,
        seconds_to_build: 1397,
      },
      {
        level: 13,
        wheat: 37447,
        wood: 36734,
        stone: 35129,
        iron: 33792,
        seconds_to_build: 1746,
      },
      {
        level: 14,
        wheat: 44937,
        wood: 44081,
        stone: 42155,
        iron: 40550,
        seconds_to_build: 2183,
      },
      {
        level: 15,
        wheat: 53924,
        wood: 52897,
        stone: 50586,
        iron: 48660,
        seconds_to_build: 2728,
      },
      {
        level: 16,
        wheat: 64709,
        wood: 63476,
        stone: 60703,
        iron: 58392,
        seconds_to_build: 3411,
      },
      {
        level: 17,
        wheat: 77651,
        wood: 76172,
        stone: 72844,
        iron: 70071,
        seconds_to_build: 4263,
      },
      {
        level: 18,
        wheat: 93181,
        wood: 91406,
        stone: 87413,
        iron: 84085,
        seconds_to_build: 5329,
      },
      {
        level: 19,
        wheat: 111818,
        wood: 109688,
        stone: 104895,
        iron: 100902,
        seconds_to_build: 6661,
      },
      {
        level: 20,
        wheat: 134181,
        wood: 131625,
        stone: 125875,
        iron: 121082,
        seconds_to_build: 8327,
      },
    ],
  },
  Brothel: {
    image: Brothel,
    description:
      "The Brothel is a unique establishment that boosts the morale of your workers, leading to increased efficiency and productivity across your city. By enhancing the happiness of your citizens, the Brothel accelerates the building speed of all structures in your kingdom. Proper management of the Brothel can significantly expedite the development and expansion of your settlement, ensuring rapid growth and prosperity.",
    levels: [
      {
        level: 1,
        wheat: 2400,
        wood: 3150,
        stone: 2760,
        iron: 2100,
        seconds_to_build: 180,
      },
      {
        level: 2,
        wheat: 3360,
        wood: 4410,
        stone: 3864,
        iron: 2940,
        seconds_to_build: 274,
      },
      {
        level: 3,
        wheat: 4704,
        wood: 6174,
        stone: 5409,
        iron: 4116,
        seconds_to_build: 416,
      },
      {
        level: 4,
        wheat: 6585,
        wood: 8643,
        stone: 7573,
        iron: 5762,
        seconds_to_build: 632,
      },
      {
        level: 5,
        wheat: 9219,
        wood: 12101,
        stone: 10602,
        iron: 8067,
        seconds_to_build: 961,
      },
      {
        level: 6,
        wheat: 12907,
        wood: 16941,
        stone: 14843,
        iron: 11294,
        seconds_to_build: 1460,
      },
      {
        level: 7,
        wheat: 18070,
        wood: 23718,
        stone: 20781,
        iron: 15812,
        seconds_to_build: 2220,
      },
      {
        level: 8,
        wheat: 25299,
        wood: 33205,
        stone: 29094,
        iron: 22136,
        seconds_to_build: 3374,
      },
      {
        level: 9,
        wheat: 35418,
        wood: 46487,
        stone: 40731,
        iron: 30991,
        seconds_to_build: 5129,
      },
      {
        level: 10,
        wheat: 49586,
        wood: 65082,
        stone: 57024,
        iron: 43388,
        seconds_to_build: 7796,
      },
    ],
  },
  Embassy: {
    image: Embassy,
    description:
      "The Embassy is the diplomatic heart of your empire, where you can forge powerful alliances or join existing ones. By establishing strong ties with other players, you can ensure mutual support and cooperation, strengthening your position in the game. Effective management of the Embassy is crucial for building a network of allies, coordinating strategic efforts, and enhancing the overall security and growth of your kingdom.",
    levels: [
      {
        level: 1,
        wheat: 2400,
        wood: 2250,
        stone: 2200,
        iron: 2020,
        seconds_to_build: 120,
      },
      {
        level: 2,
        wheat: 3120,
        wood: 2925,
        stone: 2860,
        iron: 2626,
        seconds_to_build: 148,
      },
      {
        level: 3,
        wheat: 4056,
        wood: 3802,
        stone: 3718,
        iron: 3413,
        seconds_to_build: 183,
      },
      {
        level: 4,
        wheat: 5272,
        wood: 4943,
        stone: 4833,
        iron: 4437,
        seconds_to_build: 226,
      },
      {
        level: 5,
        wheat: 6854,
        wood: 6426,
        stone: 6283,
        iron: 5769,
        seconds_to_build: 279,
      },
      {
        level: 6,
        wheat: 8911,
        wood: 8354,
        stone: 8168,
        iron: 7500,
        seconds_to_build: 345,
      },
      {
        level: 7,
        wheat: 11584,
        wood: 10860,
        stone: 10618,
        iron: 9750,
        seconds_to_build: 426,
      },
      {
        level: 8,
        wheat: 15059,
        wood: 14118,
        stone: 13804,
        iron: 12675,
        seconds_to_build: 526,
      },
      {
        level: 9,
        wheat: 19577,
        wood: 18353,
        stone: 17946,
        iron: 16477,
        seconds_to_build: 649,
      },
      {
        level: 10,
        wheat: 25450,
        wood: 23860,
        stone: 23329,
        iron: 21421,
        seconds_to_build: 802,
      },
      {
        level: 11,
        wheat: 33086,
        wood: 31018,
        stone: 30328,
        iron: 27847,
        seconds_to_build: 990,
      },
      {
        level: 12,
        wheat: 43011,
        wood: 40323,
        stone: 39427,
        iron: 36201,
        seconds_to_build: 1223,
      },
      {
        level: 13,
        wheat: 55915,
        wood: 52420,
        stone: 51255,
        iron: 47062,
        seconds_to_build: 1511,
      },
      {
        level: 14,
        wheat: 72690,
        wood: 68146,
        stone: 66632,
        iron: 61180,
        seconds_to_build: 1866,
      },
      {
        level: 15,
        wheat: 94497,
        wood: 88590,
        stone: 86622,
        iron: 79535,
        seconds_to_build: 2304,
      },
      {
        level: 16,
        wheat: 122846,
        wood: 115168,
        stone: 112608,
        iron: 103395,
        seconds_to_build: 2846,
      },
      {
        level: 17,
        wheat: 159699,
        wood: 149718,
        stone: 146391,
        iron: 134414,
        seconds_to_build: 3514,
      },
      {
        level: 18,
        wheat: 207609,
        wood: 194634,
        stone: 190309,
        iron: 174738,
        seconds_to_build: 4340,
      },
      {
        level: 19,
        wheat: 269892,
        wood: 253024,
        stone: 247401,
        iron: 227159,
        seconds_to_build: 5360,
      },
      {
        level: 20,
        wheat: 350860,
        wood: 328932,
        stone: 321622,
        iron: 295307,
        seconds_to_build: 6620,
      },
    ],
  },
  Mapchamber: {
    image: Mapchamber,
    description:
      "The Mapchamber is a vital facility for expanding your empire. Here, you can train founders, equipping them with the skills and knowledge needed to establish new cities. By strategically planning and deploying your founders, you can extend your influence and control over new territories. Proper management of the Mapchamber is essential for the calculated and successful growth of your kingdom. Every 5 levels you can make a new city.",
    levels: [
      {
        level: 1,
        wheat: 5700,
        wood: 5170,
        stone: 6200,
        iron: 5950,
        seconds_to_build: 270,
      },
      {
        level: 2,
        wheat: 8550,
        wood: 7755,
        stone: 9300,
        iron: 8925,
        seconds_to_build: 340,
      },
      {
        level: 3,
        wheat: 12825,
        wood: 11632,
        stone: 13950,
        iron: 13387,
        seconds_to_build: 429,
      },
      {
        level: 4,
        wheat: 19237,
        wood: 17448,
        stone: 20925,
        iron: 20081,
        seconds_to_build: 540,
      },
      {
        level: 5,
        wheat: 28856,
        wood: 26173,
        stone: 31387,
        iron: 30121,
        seconds_to_build: 681,
      },
      {
        level: 6,
        wheat: 43284,
        wood: 39259,
        stone: 47081,
        iron: 45182,
        seconds_to_build: 857,
      },
      {
        level: 7,
        wheat: 64926,
        wood: 58889,
        stone: 70621,
        iron: 67774,
        seconds_to_build: 1080,
      },
      {
        level: 8,
        wheat: 97389,
        wood: 88334,
        stone: 105932,
        iron: 101661,
        seconds_to_build: 1361,
      },
      {
        level: 9,
        wheat: 146084,
        wood: 132501,
        stone: 158899,
        iron: 152491,
        seconds_to_build: 1715,
      },
      {
        level: 10,
        wheat: 219127,
        wood: 198752,
        stone: 238348,
        iron: 228737,
        seconds_to_build: 2161,
      },
      {
        level: 11,
        wheat: 328690,
        wood: 298128,
        stone: 357523,
        iron: 343106,
        seconds_to_build: 2723,
      },
      {
        level: 12,
        wheat: 493036,
        wood: 447192,
        stone: 536284,
        iron: 514660,
        seconds_to_build: 3431,
      },
      {
        level: 13,
        wheat: 739554,
        wood: 670788,
        stone: 804427,
        iron: 771990,
        seconds_to_build: 4323,
      },
      {
        level: 14,
        wheat: 1100000,
        wood: 1010000,
        stone: 1210000,
        iron: 1160000,
        seconds_to_build: 5447,
      },
      {
        level: 15,
        wheat: 1670000,
        wood: 1510000,
        stone: 1810000,
        iron: 1740000,
        seconds_to_build: 6864,
      },
      {
        level: 16,
        wheat: 2490000,
        wood: 2260000,
        stone: 2720000,
        iron: 2610000,
        seconds_to_build: 8648,
      },
      {
        level: 17,
        wheat: 3740000,
        wood: 3390000,
        stone: 4070000,
        iron: 3910000,
        seconds_to_build: 10897,
      },
      {
        level: 18,
        wheat: 5610000,
        wood: 5090000,
        stone: 6110000,
        iron: 5860000,
        seconds_to_build: 13730,
      },
      {
        level: 19,
        wheat: 8420000,
        wood: 7640000,
        stone: 9160000,
        iron: 8790000,
        seconds_to_build: 17300,
      },
      {
        level: 20,
        wheat: 11640000,
        wood: 11500000,
        stone: 11740000,
        iron: 12000000,
        seconds_to_build: 21797,
      },
    ],
  },
  Barracks: {
    image: Barracks,
    description:
      "Train and prepare your soldiers in the Barracks, transforming recruits into disciplined warriors ready to defend your kingdom and conquer new territories. Level it up higher to access better troops and faster training time!",
    levels: [
      {
        level: 1,
        wheat: 1200,
        wood: 1100,
        stone: 950,
        iron: 1300,
        seconds_to_build: 90,
      },
      {
        level: 2,
        wheat: 1776,
        wood: 1628,
        stone: 1406,
        iron: 1924,
        seconds_to_build: 116,
      },
      {
        level: 3,
        wheat: 2628,
        wood: 2409,
        stone: 2080,
        iron: 2847,
        seconds_to_build: 150,
      },
      {
        level: 4,
        wheat: 3890,
        wood: 3565,
        stone: 3079,
        iron: 4214,
        seconds_to_build: 193,
      },
      {
        level: 5,
        wheat: 5757,
        wood: 5277,
        stone: 4557,
        iron: 6237,
        seconds_to_build: 249,
      },
      {
        level: 6,
        wheat: 20,
        wood: 7810,
        stone: 6745,
        iron: 9231,
        seconds_to_build: 322,
      },
      {
        level: 7,
        wheat: 12611,
        wood: 11560,
        stone: 9983,
        iron: 13661,
        seconds_to_build: 415,
      },
      {
        level: 8,
        wheat: 18664,
        wood: 17109,
        stone: 14775,
        iron: 20219,
        seconds_to_build: 535,
      },
      {
        level: 9,
        wheat: 27623,
        wood: 25321,
        stone: 21868,
        iron: 29925,
        seconds_to_build: 690,
      },
      {
        level: 10,
        wheat: 40882,
        wood: 37475,
        stone: 32365,
        iron: 44289,
        seconds_to_build: 890,
      },
      {
        level: 11,
        wheat: 60505,
        wood: 55463,
        stone: 47900,
        iron: 65548,
        seconds_to_build: 1149,
      },
      {
        level: 12,
        wheat: 89548,
        wood: 82086,
        stone: 70892,
        iron: 97011,
        seconds_to_build: 1482,
      },
      {
        level: 13,
        wheat: 132532,
        wood: 121487,
        stone: 104921,
        iron: 143576,
        seconds_to_build: 1911,
      },
      {
        level: 14,
        wheat: 196147,
        wood: 179802,
        stone: 155283,
        iron: 212493,
        seconds_to_build: 2466,
      },
      {
        level: 15,
        wheat: 290298,
        wood: 266107,
        stone: 229819,
        iron: 314490,
        seconds_to_build: 3181,
      },
      {
        level: 16,
        wheat: 429642,
        wood: 393838,
        stone: 340133,
        iron: 465445,
        seconds_to_build: 4103,
      },
      {
        level: 17,
        wheat: 635870,
        wood: 582881,
        stone: 503397,
        iron: 688859,
        seconds_to_build: 5293,
      },
      {
        level: 18,
        wheat: 941088,
        wood: 862664,
        stone: 745028,
        iron: 1019512,
        seconds_to_build: 6828,
      },
      {
        level: 19,
        wheat: 1390000,
        wood: 1280000,
        stone: 1100000,
        iron: 1510000,
        seconds_to_build: 8808,
      },
      {
        level: 20,
        wheat: 2061359,
        wood: 1889579,
        stone: 1631909,
        iron: 2233139,
        seconds_to_build: 11362,
      },
    ],
  },
  Marketplace: {
    image: Marketplace,
    description:
      "The Marketplace is the bustling center of commerce within your kingdom. Here, you can trade resources with other players, strategically exchanging goods to balance your supplies and support your kingdom's growth. By effectively managing your trades, you can ensure a steady flow of essential materials, fostering the development and prosperity of your settlement. The Marketplace is crucial for maintaining economic stability and building strong trade relationships.",
    levels: [
      {
        level: 1,
        wheat: 2250,
        wood: 2190,
        stone: 2110,
        iron: 1960,
        seconds_to_build: 75,
      },
      {
        level: 2,
        wheat: 2812,
        wood: 2737,
        stone: 2637,
        iron: 2450,
        seconds_to_build: 98,
      },
      {
        level: 3,
        wheat: 3515,
        wood: 3421,
        stone: 3296,
        iron: 3062,
        seconds_to_build: 129,
      },
      {
        level: 4,
        wheat: 4394,
        wood: 4277,
        stone: 4121,
        iron: 3828,
        seconds_to_build: 169,
      },
      {
        level: 5,
        wheat: 5493,
        wood: 5346,
        stone: 5151,
        iron: 4785,
        seconds_to_build: 221,
      },
      {
        level: 6,
        wheat: 6866,
        wood: 6683,
        stone: 6439,
        iron: 5981,
        seconds_to_build: 289,
      },
      {
        level: 7,
        wheat: 8583,
        wood: 8354,
        stone: 8049,
        iron: 7476,
        seconds_to_build: 379,
      },
      {
        level: 8,
        wheat: 10728,
        wood: 10442,
        stone: 10061,
        iron: 9346,
        seconds_to_build: 497,
      },
      {
        level: 9,
        wheat: 13411,
        wood: 13053,
        stone: 12576,
        iron: 11682,
        seconds_to_build: 650,
      },
      {
        level: 10,
        wheat: 16763,
        wood: 16316,
        stone: 15720,
        iron: 14603,
        seconds_to_build: 852,
      },
      {
        level: 11,
        wheat: 20954,
        wood: 20395,
        stone: 19650,
        iron: 18253,
        seconds_to_build: 1116,
      },
      {
        level: 12,
        wheat: 26193,
        wood: 25494,
        stone: 24563,
        iron: 22817,
        seconds_to_build: 1462,
      },
      {
        level: 13,
        wheat: 32741,
        wood: 31868,
        stone: 30704,
        iron: 28521,
        seconds_to_build: 1916,
      },
      {
        level: 14,
        wheat: 40927,
        wood: 39835,
        stone: 38380,
        iron: 35652,
        seconds_to_build: 2510,
      },
      {
        level: 15,
        wheat: 51159,
        wood: 49794,
        stone: 47975,
        iron: 44565,
        seconds_to_build: 3287,
      },
      {
        level: 16,
        wheat: 63948,
        wood: 62243,
        stone: 59969,
        iron: 55706,
        seconds_to_build: 4307,
      },
      {
        level: 17,
        wheat: 79936,
        wood: 77804,
        stone: 74962,
        iron: 69633,
        seconds_to_build: 5642,
      },
      {
        level: 18,
        wheat: 99920,
        wood: 97255,
        stone: 93702,
        iron: 87041,
        seconds_to_build: 7390,
      },
      {
        level: 19,
        wheat: 124900,
        wood: 121569,
        stone: 117128,
        iron: 108801,
        seconds_to_build: 9682,
      },
      {
        level: 20,
        wheat: 156125,
        wood: 151961,
        stone: 146410,
        iron: 136002,
        seconds_to_build: 12683,
      },
    ],
  },
  Church: {
    image: ChurchIcon,
    description:
      "Get access to 4 different skilltrees. Earn experience and level them up. Unlock new abilities and upgrades. New mechanics and alot more!",
    levels: [
      {
        level: 1,
        wheat: 5500,
        wood: 5200,
        stone: 5900,
        iron: 6200,
        seconds_to_build: 550,
      },
      {
        level: 2,
        wheat: 7200,
        wood: 6900,
        stone: 7500,
        iron: 8100,
        seconds_to_build: 900,
      },
      {
        level: 3,
        wheat: 9300,
        wood: 8700,
        stone: 8200,
        iron: 8900,
        seconds_to_build: 1300,
      },
      {
        level: 4,
        wheat: 13000,
        wood: 12600,
        stone: 11900,
        iron: 14500,
        seconds_to_build: 1500,
      },
      {
        level: 5,
        wheat: 17500,
        wood: 15900,
        stone: 16700,
        iron: 18900,
        seconds_to_build: 1900,
      },
      {
        level: 6,
        wheat: 21200,
        wood: 24000,
        stone: 23600,
        iron: 25000,
        seconds_to_build: 2500,
      },
      {
        level: 7,
        wheat: 36000,
        wood: 35600,
        stone: 33300,
        iron: 38900,
        seconds_to_build: 3100,
      },
      {
        level: 8,
        wheat: 50000,
        wood: 48900,
        stone: 52400,
        iron: 55000,
        seconds_to_build: 3900,
      },
      {
        level: 9,
        wheat: 72000,
        wood: 69300,
        stone: 74600,
        iron: 75000,
        seconds_to_build: 4500,
      },
      {
        level: 10,
        wheat: 105000,
        wood: 100000,
        stone: 99900,
        iron: 105000,
        seconds_to_build: 5500,
      },
    ],
  },
  CaligulasPalace: {
    image: CaligulasPalaceIcon,
    description:
      "The rarest drop of an upgraded loot chest. Gains the power to distract a part of attacking troops with lust and gluttony, and increase your odds of winning the battle! Is upgradeable with Casino coins",
    levels: [
      {
        level: 1,
        wheat: 300,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 3600,
      },
      {
        level: 2,
        wheat: 500,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 7200,
      },
      {
        level: 3,
        wheat: 1000,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 11809,
      },
      {
        level: 4,
        wheat: 2000,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 15809,
      },
      {
        level: 5,
        wheat: 5000,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 19809,
      },
      {
        level: 6,
        wheat: 10000,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 23809,
      },
      {
        level: 7,
        wheat: 20000,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 26809,
      },
      {
        level: 8,
        wheat: 40000,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 29809,
      },
      {
        level: 9,
        wheat: 70000,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 31809,
      },
      {
        level: 10,
        wheat: 100000,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 35809,
      },
    ],
  },
  ArtifexFoundry: {
    image: ArtifexFoundryIcon,
    description:
      "The most powerful building in the world. It's a place where you can build your own troops and upgrade them. You can also build upgrades for your troops, which will give them new abilities and upgrades. The more troops you have, the more powerful they will be!",
    levels: [
      {
        level: 1,
        wheat: 12000,
        wood: 12600,
        stone: 12250,
        iron: 15900,
        seconds_to_build: 520,
      },
      {
        level: 2,
        wheat: 16500,
        wood: 14900,
        stone: 15700,
        iron: 20900,
        seconds_to_build: 750,
      },
      {
        level: 3,
        wheat: 19150,
        wood: 23900,
        stone: 23200,
        iron: 26700,
        seconds_to_build: 1050,
      },
      {
        level: 4,
        wheat: 24100,
        wood: 27400,
        stone: 29400,
        iron: 34200,
        seconds_to_build: 1450,
      },
      {
        level: 5,
        wheat: 30900,
        wood: 35200,
        stone: 38200,
        iron: 44500,
        seconds_to_build: 2000,
      },
      {
        level: 6,
        wheat: 39750,
        wood: 44000,
        stone: 47850,
        iron: 56100,
        seconds_to_build: 2600,
      },
      {
        level: 7,
        wheat: 49650,
        wood: 54000,
        stone: 59000,
        iron: 69100,
        seconds_to_build: 3300,
      },
      {
        level: 8,
        wheat: 62000,
        wood: 67500,
        stone: 73250,
        iron: 85700,
        seconds_to_build: 4100,
      },
      {
        level: 9,
        wheat: 77500,
        wood: 84800,
        stone: 91000,
        iron: 106200,
        seconds_to_build: 5000,
      },
      {
        level: 10,
        wheat: 97000,
        wood: 106500,
        stone: 113000,
        iron: 131500,
        seconds_to_build: 6000,
      },
      {
        level: 11,
        wheat: 122000,
        wood: 133500,
        stone: 140500,
        iron: 162500,
        seconds_to_build: 7200,
      },
      {
        level: 12,
        wheat: 153500,
        wood: 167500,
        stone: 174500,
        iron: 200500,
        seconds_to_build: 8600,
      },
      {
        level: 13,
        wheat: 192500,
        wood: 210500,
        stone: 217500,
        iron: 247000,
        seconds_to_build: 10200,
      },
      {
        level: 14,
        wheat: 241000,
        wood: 265000,
        stone: 271500,
        iron: 304500,
        seconds_to_build: 12100,
      },
      {
        level: 15,
        wheat: 302500,
        wood: 334500,
        stone: 340500,
        iron: 375000,
        seconds_to_build: 14400,
      },
      {
        level: 16,
        wheat: 380500,
        wood: 422000,
        stone: 428000,
        iron: 462500,
        seconds_to_build: 17200,
      },
      {
        level: 17,
        wheat: 478000,
        wood: 533500,
        stone: 538000,
        iron: 570500,
        seconds_to_build: 20600,
      },
      {
        level: 18,
        wheat: 600000,
        wood: 674000,
        stone: 675000,
        iron: 702000,
        seconds_to_build: 24700,
      },
      {
        level: 19,
        wheat: 753000,
        wood: 851000,
        stone: 845000,
        iron: 863000,
        seconds_to_build: 29600,
      },
      {
        level: 20,
        wheat: 944000,
        wood: 1075000,
        stone: 1055000,
        iron: 1065000,
        seconds_to_build: 35500,
      },
    ],
  },
  Wall: {
    image: Wall,
    description:
      "The wall is the best defensive structure you can build in your city. It is a strong barrier that protects your city from attacks and keeps your troops safe. The wall increases the defence power with (10 x wall level)%. ",
    levels: [
      {
        level: 1,
        wheat: 0,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 180,
      },
      {
        level: 2,
        wheat: 22000,
        wood: 19500,
        stone: 25700,
        iron: 26800,
        seconds_to_build: 180,
      },
      {
        level: 3,
        wheat: 26700,
        wood: 24900,
        stone: 32400,
        iron: 31200,
        seconds_to_build: 180,
      },
      {
        level: 4,
        wheat: 31200,
        wood: 29700,
        stone: 39200,
        iron: 37100,
        seconds_to_build: 180,
      },
      {
        level: 5,
        wheat: 39200,
        wood: 35400,
        stone: 45200,
        iron: 43600,
        seconds_to_build: 180,
      },
    ],
  },
  Wheat: {
    image: WheatIcon,
    description: "The wheat fields.",
    levels: [
      {
        level: 1,
        wheat: 0,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 0,
        effect: 64,
        population: 1,
      },
      {
        level: 2,
        wheat: 250,
        wood: 350,
        stone: 300,
        iron: 280,
        seconds_to_build: 45,
        effect: 3,
        population: 1,
      },
      {
        level: 3,
        wheat: 400,
        wood: 560,
        stone: 480,
        iron: 448,
        seconds_to_build: 60,
        effect: 5,
        population: 1,
      },
      {
        level: 4,
        wheat: 640,
        wood: 896,
        stone: 768,
        iron: 716,
        seconds_to_build: 82,
        effect: 7,
        population: 1,
      },
      {
        level: 5,
        wheat: 1024,
        wood: 1433,
        stone: 1228,
        iron: 1146,
        seconds_to_build: 110,
        effect: 10,
        population: 1,
      },
      {
        level: 6,
        wheat: 1638,
        wood: 2293,
        stone: 1966,
        iron: 1835,
        seconds_to_build: 149,
        effect: 15,
        population: 1,
      },
      {
        level: 7,
        wheat: 2621,
        wood: 3670,
        stone: 3145,
        iron: 2936,
        seconds_to_build: 201,
        effect: 22,
        population: 2,
      },
      {
        level: 8,
        wheat: 4194,
        wood: 5872,
        stone: 5033,
        iron: 4697,
        seconds_to_build: 272,
        effect: 34,
        population: 2,
      },
      {
        level: 9,
        wheat: 6710,
        wood: 9395,
        stone: 8053,
        iron: 7516,
        seconds_to_build: 367,
        effect: 51,
        population: 2,
      },
      {
        level: 10,
        wheat: 10737,
        wood: 15032,
        stone: 12884,
        iron: 12025,
        seconds_to_build: 496,
        effect: 76,
        population: 2,
      },
      {
        level: 11,
        wheat: 17179,
        wood: 24051,
        stone: 20615,
        iron: 19241,
        seconds_to_build: 670,
        effect: 115,
        population: 7,
      },
      {
        level: 12,
        wheat: 27487,
        wood: 38482,
        stone: 32985,
        iron: 30786,
        seconds_to_build: 904,
        effect: 173,
        population: 7,
      },
      {
        level: 13,
        wheat: 43980,
        wood: 61572,
        stone: 52776,
        iron: 49258,
        seconds_to_build: 1221,
        effect: 259,
        population: 8,
      },
      {
        level: 14,
        wheat: 70368,
        wood: 98516,
        stone: 84442,
        iron: 78812,
        seconds_to_build: 1648,
        effect: 389,
        population: 8,
      },
      {
        level: 15,
        wheat: 112589,
        wood: 157625,
        stone: 135107,
        iron: 126100,
        seconds_to_build: 2226,
        effect: 583,
        population: 9,
      },
      {
        level: 16,
        wheat: 180143,
        wood: 252201,
        stone: 216172,
        iron: 201761,
        seconds_to_build: 3005,
        effect: 875,
        population: 9,
      },
      {
        level: 17,
        wheat: 288230,
        wood: 403522,
        stone: 345876,
        iron: 322818,
        seconds_to_build: 4057,
        effect: 1313,
        population: 10,
      },
      {
        level: 18,
        wheat: 461168,
        wood: 645636,
        stone: 553402,
        iron: 516508,
        seconds_to_build: 5477,
        effect: 1970,
        population: 10,
      },
      {
        level: 19,
        wheat: 977869,
        wood: 1033017,
        stone: 885443,
        iron: 826414,
        seconds_to_build: 7394,
        effect: 2955,
        population: 11,
      },
      {
        level: 20,
        wheat: 1180000,
        wood: 2640000,
        stone: 2260000,
        iron: 2120000,
        seconds_to_build: 9982,
        effect: 4433,
        population: 11,
      },
    ],
  },
  Wood: {
    image: WoodIcon,
    description: "The wood fields.",
    levels: [
      {
        level: 1,
        wheat: 0,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 0,
        effect: 54,
        population: 1,
      },
      {
        level: 2,
        wheat: 320,
        wood: 270,
        stone: 290,
        iron: 320,
        seconds_to_build: 60,
        effect: 3,
        population: 2,
      },
      {
        level: 3,
        wheat: 512,
        wood: 432,
        stone: 464,
        iron: 512,
        seconds_to_build: 81,
        effect: 4,
        population: 2,
      },
      {
        level: 4,
        wheat: 819,
        wood: 691,
        stone: 742,
        iron: 819,
        seconds_to_build: 109,
        effect: 6,
        population: 2,
      },
      {
        level: 5,
        wheat: 1310,
        wood: 1105,
        stone: 1187,
        iron: 1310,
        seconds_to_build: 147,
        effect: 8,
        population: 2,
      },
      {
        level: 6,
        wheat: 2097,
        wood: 1769,
        stone: 1900,
        iron: 2097,
        seconds_to_build: 199,
        effect: 12,
        population: 2,
      },
      {
        level: 7,
        wheat: 3355,
        wood: 2831,
        stone: 3040,
        iron: 3355,
        seconds_to_build: 269,
        effect: 19,
        population: 2,
      },
      {
        level: 8,
        wheat: 5368,
        wood: 4529,
        stone: 4865,
        iron: 5368,
        seconds_to_build: 363,
        effect: 28,
        population: 2,
      },
      {
        level: 9,
        wheat: 8589,
        wood: 7247,
        stone: 7784,
        iron: 8589,
        seconds_to_build: 490,
        effect: 43,
        population: 3,
      },
      {
        level: 10,
        wheat: 13743,
        wood: 11596,
        stone: 12455,
        iron: 13743,
        seconds_to_build: 661,
        effect: 64,
        population: 3,
      },
      {
        level: 11,
        wheat: 21990,
        wood: 18554,
        stone: 19928,
        iron: 21990,
        seconds_to_build: 893,
        effect: 96,
        population: 7,
      },
      {
        level: 12,
        wheat: 35184,
        wood: 29686,
        stone: 31885,
        iron: 35184,
        seconds_to_build: 1206,
        effect: 144,
        population: 8,
      },
      {
        level: 13,
        wheat: 56295,
        wood: 47498,
        stone: 51017,
        iron: 56295,
        seconds_to_build: 1628,
        effect: 216,
        population: 9,
      },
      {
        level: 14,
        wheat: 90071,
        wood: 75998,
        stone: 81627,
        iron: 90071,
        seconds_to_build: 2198,
        effect: 324,
        population: 9,
      },
      {
        level: 15,
        wheat: 144115,
        wood: 121597,
        stone: 130604,
        iron: 144115,
        seconds_to_build: 2968,
        effect: 486,
        population: 10,
      },
      {
        level: 16,
        wheat: 230584,
        wood: 194555,
        stone: 208967,
        iron: 230584,
        seconds_to_build: 4007,
        effect: 729,
        population: 10,
      },
      {
        level: 17,
        wheat: 368934,
        wood: 311288,
        stone: 334347,
        iron: 368934,
        seconds_to_build: 5409,
        effect: 1094,
        population: 11,
      },
      {
        level: 18,
        wheat: 590295,
        wood: 498062,
        stone: 534955,
        iron: 590295,
        seconds_to_build: 7302,
        effect: 1642,
        population: 11,
      },
      {
        level: 19,
        wheat: 944473,
        wood: 796899,
        stone: 855928,
        iron: 944473,
        seconds_to_build: 9858,
        effect: 2463,
        population: 12,
      },
      {
        level: 20,
        wheat: 1510000,
        wood: 1280000,
        stone: 1370000,
        iron: 1510000,
        seconds_to_build: 13309,
        effect: 3694,
        population: 12,
      },
    ],
  },
  Stone: {
    image: StoneIcon,
    description: "The stone fields.",
    levels: [
      {
        level: 1,
        wheat: 0,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 0,
        effect: 40,
        population: 1,
      },
      {
        level: 2,
        wheat: 310,
        wood: 340,
        stone: 270,
        iron: 350,
        seconds_to_build: 75,
        effect: 2,
        population: 2,
      },
      {
        level: 3,
        wheat: 496,
        wood: 544,
        stone: 432,
        iron: 560,
        seconds_to_build: 100,
        effect: 3,
        population: 2,
      },
      {
        level: 4,
        wheat: 793,
        wood: 870,
        stone: 691,
        iron: 896,
        seconds_to_build: 134,
        effect: 5,
        population: 2,
      },
      {
        level: 5,
        wheat: 1269,
        wood: 1392,
        stone: 1105,
        iron: 1433,
        seconds_to_build: 180,
        effect: 8,
        population: 2,
      },
      {
        level: 6,
        wheat: 2031,
        wood: 2228,
        stone: 1769,
        iron: 2293,
        seconds_to_build: 241,
        effect: 10,
        population: 3,
      },
      {
        level: 7,
        wheat: 3250,
        wood: 3565,
        stone: 2831,
        iron: 3670,
        seconds_to_build: 324,
        effect: 15,
        population: 3,
      },
      {
        level: 8,
        wheat: 5200,
        wood: 5704,
        stone: 4529,
        iron: 5872,
        seconds_to_build: 434,
        effect: 22,
        population: 3,
      },
      {
        level: 9,
        wheat: 8321,
        wood: 9126,
        stone: 7247,
        iron: 9395,
        seconds_to_build: 581,
        effect: 34,
        population: 3,
      },
      {
        level: 10,
        wheat: 13314,
        wood: 14602,
        stone: 11596,
        iron: 15032,
        seconds_to_build: 779,
        effect: 51,
        population: 4,
      },
      {
        level: 11,
        wheat: 21303,
        wood: 23364,
        stone: 18554,
        iron: 24051,
        seconds_to_build: 1044,
        effect: 76,
        population: 7,
      },
      {
        level: 12,
        wheat: 34084,
        wood: 37383,
        stone: 29686,
        iron: 38482,
        seconds_to_build: 1399,
        effect: 115,
        population: 8,
      },
      {
        level: 13,
        wheat: 54535,
        wood: 59813,
        stone: 47498,
        iron: 61572,
        seconds_to_build: 1875,
        effect: 173,
        population: 9,
      },
      {
        level: 14,
        wheat: 87257,
        wood: 95701,
        stone: 75998,
        iron: 98516,
        seconds_to_build: 2513,
        effect: 259,
        population: 9,
      },
      {
        level: 15,
        wheat: 139611,
        wood: 153122,
        stone: 121597,
        iron: 157625,
        seconds_to_build: 3368,
        effect: 389,
        population: 10,
      },
      {
        level: 16,
        wheat: 223378,
        wood: 244995,
        stone: 194555,
        iron: 252201,
        seconds_to_build: 4513,
        effect: 583,
        population: 10,
      },
      {
        level: 17,
        wheat: 357405,
        wood: 391993,
        stone: 311288,
        iron: 403522,
        seconds_to_build: 6048,
        effect: 875,
        population: 11,
      },
      {
        level: 18,
        wheat: 571849,
        wood: 627189,
        stone: 498062,
        iron: 645636,
        seconds_to_build: 8104,
        effect: 1313,
        population: 11,
      },
      {
        level: 19,
        wheat: 914958,
        wood: 1003502,
        stone: 796899,
        iron: 1033017,
        seconds_to_build: 10860,
        effect: 1970,
        population: 12,
      },
      {
        level: 20,
        wheat: 1460000,
        wood: 1600000,
        stone: 1270000,
        iron: 1650000,
        seconds_to_build: 14552,
        effect: 2955,
        population: 12,
      },
    ],
  },
  Iron: {
    image: IronIcon,
    description: "The Iron mines.",
    levels: [
      {
        level: 1,
        wheat: 0,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 0,
        effect: 25,
        population: 1,
      },
      {
        level: 2,
        wheat: 315,
        wood: 295,
        stone: 360,
        iron: 280,
        seconds_to_build: 90,
        effect: 2,
        population: 2,
      },
      {
        level: 3,
        wheat: 504,
        wood: 472,
        stone: 576,
        iron: 448,
        seconds_to_build: 120,
        effect: 3,
        population: 2,
      },
      {
        level: 4,
        wheat: 806,
        wood: 755,
        stone: 921,
        iron: 716,
        seconds_to_build: 161,
        effect: 4,
        population: 3,
      },
      {
        level: 5,
        wheat: 1290,
        wood: 1208,
        stone: 1474,
        iron: 1146,
        seconds_to_build: 216,
        effect: 6,
        population: 3,
      },
      {
        level: 6,
        wheat: 2064,
        wood: 1933,
        stone: 2359,
        iron: 1835,
        seconds_to_build: 290,
        effect: 8,
        population: 3,
      },
      {
        level: 7,
        wheat: 3303,
        wood: 3093,
        stone: 3774,
        iron: 2936,
        seconds_to_build: 388,
        effect: 12,
        population: 4,
      },
      {
        level: 8,
        wheat: 5284,
        wood: 4949,
        stone: 6039,
        iron: 4697,
        seconds_to_build: 521,
        effect: 18,
        population: 4,
      },
      {
        level: 9,
        wheat: 8455,
        wood: 7918,
        stone: 9663,
        iron: 7516,
        seconds_to_build: 698,
        effect: 27,
        population: 4,
      },
      {
        level: 10,
        wheat: 13529,
        wood: 12670,
        stone: 15461,
        iron: 12025,
        seconds_to_build: 935,
        effect: 41,
        population: 5,
      },
      {
        level: 11,
        wheat: 21646,
        wood: 20272,
        stone: 24739,
        iron: 19241,
        seconds_to_build: 1253,
        effect: 62,
        population: 8,
      },
      {
        level: 12,
        wheat: 34634,
        wood: 32435,
        stone: 39582,
        iron: 30786,
        seconds_to_build: 1679,
        effect: 94,
        population: 9,
      },
      {
        level: 13,
        wheat: 55415,
        wood: 51896,
        stone: 63331,
        iron: 49258,
        seconds_to_build: 2251,
        effect: 142,
        population: 10,
      },
      {
        level: 14,
        wheat: 88664,
        wood: 83035,
        stone: 101330,
        iron: 78812,
        seconds_to_build: 3016,
        effect: 214,
        population: 10,
      },
      {
        level: 15,
        wheat: 141863,
        wood: 132856,
        stone: 162129,
        iron: 126100,
        seconds_to_build: 4042,
        effect: 324,
        population: 11,
      },
      {
        level: 16,
        wheat: 226981,
        wood: 212569,
        stone: 259407,
        iron: 201761,
        seconds_to_build: 5416,
        effect: 490,
        population: 11,
      },
      {
        level: 17,
        wheat: 363170,
        wood: 340111,
        stone: 415051,
        iron: 322818,
        seconds_to_build: 7257,
        effect: 740,
        population: 12,
      },
      {
        level: 18,
        wheat: 581072,
        wood: 544178,
        stone: 664082,
        iron: 516508,
        seconds_to_build: 9725,
        effect: 1117,
        population: 12,
      },
      {
        level: 19,
        wheat: 929715,
        wood: 870686,
        stone: 1062532,
        iron: 826414,
        seconds_to_build: 13032,
        effect: 1687,
        population: 13,
      },
      {
        level: 20,
        wheat: 1480000,
        wood: 1390000,
        stone: 1700000,
        iron: 1320000,
        seconds_to_build: 17463,
        effect: 2548,
        population: 13,
      },
    ],
  },
  VictoryMonument: {
    image: VictoryMonument,
    description:
      "Victory Monument is a building that can strictly be build in a players capital city after the endgame has started. This building is upgradeable with Jades earned from the endgame NPC. Getting the building to level 50 would end the game and declare the player the winner.",
    levels: [
      {
        level: 1,
        wheat: 11,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 300,
      },
      {
        level: 2,
        wheat: 15,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 332,
      },
      {
        level: 3,
        wheat: 22,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 367,
      },
      {
        level: 4,
        wheat: 30,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 406,
      },
      {
        level: 5,
        wheat: 40,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 450,
      },
      {
        level: 6,
        wheat: 55,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 498,
      },
      {
        level: 7,
        wheat: 75,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 551,
      },
      {
        level: 8,
        wheat: 100,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 610,
      },
      {
        level: 9,
        wheat: 130,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 675,
      },
      {
        level: 10,
        wheat: 170,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 747,
      },
      {
        level: 11,
        wheat: 220,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 827,
      },
      {
        level: 12,
        wheat: 280,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 915,
      },
      {
        level: 13,
        wheat: 360,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 1013,
      },
      {
        level: 14,
        wheat: 450,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 1121,
      },
      {
        level: 15,
        wheat: 550,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 1241,
      },
      {
        level: 16,
        wheat: 660,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 1373,
      },
      {
        level: 17,
        wheat: 790,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 1520,
      },
      {
        level: 18,
        wheat: 940,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 1682,
      },
      {
        level: 19,
        wheat: 1200,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 1862,
      },
      {
        level: 20,
        wheat: 1560,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 2060,
      },
      {
        level: 21,
        wheat: 1860,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 2280,
      },
      {
        level: 22,
        wheat: 2350,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 2524,
      },
      {
        level: 23,
        wheat: 2900,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 2793,
      },
      {
        level: 24,
        wheat: 3600,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 3091,
      },
      {
        level: 25,
        wheat: 4500,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 3421,
      },
      {
        level: 26,
        wheat: 5500,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 3787,
      },
      {
        level: 27,
        wheat: 6650,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 4191,
      },
      {
        level: 28,
        wheat: 7890,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 4638,
      },
      {
        level: 29,
        wheat: 9100,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 5134,
      },
      {
        level: 30,
        wheat: 10860,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 5682,
      },
      {
        level: 31,
        wheat: 12100,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 6288,
      },
      {
        level: 32,
        wheat: 15600,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 6960,
      },
      {
        level: 33,
        wheat: 19400,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 7703,
      },
      {
        level: 34,
        wheat: 24900,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 8525,
      },
      {
        level: 35,
        wheat: 31200,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 9435,
      },
      {
        level: 36,
        wheat: 38900,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 10442,
      },
      {
        level: 37,
        wheat: 46900,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 11557,
      },
      {
        level: 38,
        wheat: 53800,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 12791,
      },
      {
        level: 39,
        wheat: 62100,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 14156,
      },
      {
        level: 40,
        wheat: 72900,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 15667,
      },
      {
        level: 41,
        wheat: 83800,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 17340,
      },
      {
        level: 42,
        wheat: 95000,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 19191,
      },
      {
        level: 43,
        wheat: 109000,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 21239,
      },
      {
        level: 44,
        wheat: 123800,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 23506,
      },
      {
        level: 45,
        wheat: 131400,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 26016,
      },
      {
        level: 46,
        wheat: 139500,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 28793,
      },
      {
        level: 47,
        wheat: 148000,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 31866,
      },
      {
        level: 48,
        wheat: 168400,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 35268,
      },
      {
        level: 49,
        wheat: 180100,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 39033,
      },
      {
        level: 50,
        wheat: 200000,
        wood: 0,
        stone: 0,
        iron: 0,
        seconds_to_build: 43200,
      },
    ],
  },
  Fighter: {
    image: FighterIcon,
    description:
      "The Fighter strategy emphasizes military strength and aggressive expansion. Players adopting this approach focus on rapidly building and upgrading their Barracks, training a large and powerful army. By prioritizing the production of combat units like Club Men, Spearmen, and Axemen, Fighters aim to dominate the battlefield, raid enemy settlements, and conquer new territories. This strategy requires a steady flow of resources to sustain troop production, so efficient management of resource-gathering facilities is still important. Fighters often form strong alliances through the Embassy to support their military campaigns and ensure a united front against common enemies. This strategy is ideal for players who enjoy fast-paced, combat-focused gameplay and seek to establish dominance through sheer military might.",
    levels: [
      {
        level: 0,
        wheat: 0,
        wood: 0,
        stone: 0,
        iron: 0,
      },
    ],
  },
  Farmer: {
    image: FarmerIcon,
    description:
      "The Farmer strategy focuses on maximizing resource production and economic growth. By prioritizing the development of resource-gathering facilities like the Wood Field, Stone Mine, Iron Refinery, and Wheat Fields, you ensure a steady supply of essential materials. This approach allows you to build a strong economic foundation, enabling rapid expansion and the ability to support a large army in the future. Farmers invest in upgrading their storage facilities, like the Warehouse, to protect their resources and maintain continuous growth. This strategy is ideal for players who prefer to build a powerful empire through steady development and efficient resource management.",
    levels: [
      {
        level: 0,
        wheat: 0,
        wood: 0,
        stone: 0,
        iron: 0,
      },
    ],
  },
  Gambler: {
    image: GamblerIcon,
    description:
      "The Gambler strategy revolves around taking risks to achieve rapid gains. Players adopting this approach heavily utilize the Casino to play blackjack, spin the slots, and open loot chests in hopes of winning valuable coins. These coins can then be traded for crucial resources, providing an unpredictable but potentially lucrative boost to your empire’s growth. Gamblers invest in luck and opportunism, often relying on chance to outpace their rivals. This strategy can lead to significant resource surges, enabling swift expansion and development. However, it also requires a willingness to accept the inherent risks and variability. This approach is perfect for players who enjoy a high-risk, high-reward style of gameplay and thrive on the excitement of chance.",
    levels: [
      {
        level: 0,
        wheat: 0,
        wood: 0,
        stone: 0,
        iron: 0,
      },
    ],
  },
};
