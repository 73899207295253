import coinStack from "./images/CoinStack.png";

import fillWarehouseTokenIcon from "./images/tokens/FillWarehouseToken.png";
import raiderTokenIcon from "./images/tokens/RaiderToken.png";
import resourceBoostTokenIcon from "./images/tokens/ResourceBoostToken.png";
import troopTrainingBoostTokenIcon from "./images/tokens/TroopsTrainingToken.png";
import extraBuildingQueueTokenIcon from "./images/tokens/ExtraQueueItemToken.png";
import rerollTokenIcon from "./images/tokens/RerollToken.png";
import freeBuildingUpgradeTokenIcon from "./images/tokens/FreeBuildingUpgradeToken.png";
import puzzlePiecesIcon from "./images/puzzlePieces/PuzzlePieceLootboxIcon.png";
import clubmanIcon from "./images/troops/ClubmanIcon.png";
import axeManIcon from "./images/troops/AxemanIcon.png";
import founderIcon from "./images/troops/FounderIcon.png";
import wheatIcon from "./images/resources/WheatIcon.png";
import woodIcon from "./images/resources/WoodIcon.png";
import stoneIcon from "./images/resources/StoneIcon.png";
import ironIcon from "./images/resources/IronIcon.png";
import resGroup from "./images/resources/AllResGroups.png";
import CalugulasPalaceIcon from "./images/buildings/Caligula'sPalace.png";

export const HelperFunctions = {
  formatTime(milliseconds) {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  },
  calculate1HourCooldown(lastActivated) {
    const now = new Date();
    const lastActivatedTime = new Date(lastActivated);
    const cooldownEnd = new Date(lastActivatedTime.getTime() + 60 * 60 * 1000);
    return Math.max(0, cooldownEnd - now);
  },
  calculate24HourCooldown(lastActivated) {
    const now = new Date();
    const lastActivatedTime = new Date(lastActivated);
    const cooldownEnd = new Date(
      lastActivatedTime.getTime() + 24 * 60 * 60 * 1000
    );
    return Math.max(0, cooldownEnd - now);
  },
  secondsToTimestamp(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const paddedHours = String(hours).padStart(2, "0");
    const paddedMinutes = String(minutes).padStart(2, "0");
    const paddedSeconds = String(remainingSeconds).padStart(2, "0");
    let timestamp;
    if (hours > 0) {
      timestamp = `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
    } else if (minutes > 0) {
      timestamp = `${paddedMinutes}:${paddedSeconds}`;
    } else {
      timestamp = `${paddedSeconds}`;
    }

    return timestamp;
  },

  getCardValue(carValue) {
    if (carValue === "K" || carValue === "Q" || carValue === "J") {
      return { wasAce: false, value: 10 };
    } else if (carValue === "A") {
      return { wasAce: true, value: 11 };
    } else if (carValue === "Unknown") {
      return { wasAce: false, value: 0 };
    } else {
      return { wasAce: false, value: parseInt(carValue) };
    }
  },
  getShuffledItemPoolArray(version) {
    const resPoolV1Ordered = [
      {
        name: "3xCoins",
        rarity: "common",
        description: "You got 3 x coins",
        image: coinStack,
        text: "3x",
      },
      {
        name: "500xEachRes",
        rarity: "common",
        description: "You got 500 x each resource",
        image: resGroup,
        text: "500x",
      },
      {
        name: "5xCoins",
        rarity: "common",
        description: "You got 5 x coins",
        image: coinStack,
        text: "5x",
      },
      {
        name: "2500xEachRes",
        rarity: "common",
        description: "You got 2500 x each resource",
        image: resGroup,
        text: "2500x",
      },
      {
        name: "10xAxemen",
        rarity: "uncommon",
        description: "You got 10 x axemen",
        image: axeManIcon,
        text: "10x",
      },
      {
        name: "5000xWheat",
        rarity: "uncommon",
        description: "You got 5000 x wheat",
        image: wheatIcon,
        text: "5000x",
      },
      {
        name: "5000xWood",
        rarity: "uncommon",
        description: "You got 5000 x wood",
        image: woodIcon,
        text: "5000x",
      },
      {
        name: "5000xStone",
        rarity: "uncommon",
        description: "You got 5000 x stone",
        image: stoneIcon,
        text: "5000x",
      },
      {
        name: "5000xIron",
        rarity: "uncommon",
        description: "You got 5000 x iron",
        image: ironIcon,
        text: "5000x",
      },
      {
        name: "20xCoins",
        rarity: "rare",
        description: "You got 20 x coins",
        image: coinStack,
        text: "20x",
      },
      {
        name: "50xAxemen",
        rarity: "rare",
        description: "You got 50 x axemen",
        image: axeManIcon,
        text: "50x",
      },
      {
        name: "10000xEachRes",
        rarity: "rare",
        description: "You got 10,000 x each resource",
        image: resGroup,
        text: "10,000x",
      },
      {
        name: "50xCoins",
        rarity: "very rare",
        description: "You got 50 x coins",
        image: coinStack,
        text: "50x",
      },
      {
        name: "50000xWheat",
        rarity: "very rare",
        description: "You got 50,000 x wheat",
        image: wheatIcon,
        text: "50,000x",
      },
      {
        name: "50000xWood",
        rarity: "very rare",
        description: "You got 50,000 x wood",
        image: woodIcon,
        text: "50,000x",
      },
      {
        name: "50000xStone",
        rarity: "very rare",
        description: "You got 50,000 x stone",
        image: stoneIcon,
        text: "50,000x",
      },
      {
        name: "50000xIron",
        rarity: "very rare",
        description: "You got 50,000 x iron",
        image: ironIcon,
        text: "50,000x",
      },
      {
        name: "250xAxemen",
        rarity: "epic",
        description: "You got 250 x axemen",
        image: axeManIcon,
        text: "250x",
      },
      {
        name: "500xClubmen",
        rarity: "epic",
        description: "You got 500 x clubmen",
        image: clubmanIcon,
        text: "500x",
      },
      {
        name: "1000xCoins",
        rarity: "legendary",
        description: "You got 1000 x coins",
        image: coinStack,
        text: "1000x",
      },
    ];

    const resPoolV2Ordered = [
      {
        name: "1xUpgradeToken",
        rarity: "common",
        description: "You got instant upgrade token",
        text: "1x",
        image: freeBuildingUpgradeTokenIcon,
      },
      {
        name: "1xRerollToken",
        rarity: "common",
        description: "You got a chest reroll token.",
        text: "1x",
        image: rerollTokenIcon,
      },
      {
        name: "250xCoins",
        rarity: "common",
        description: "You got 250 coins.",
        text: "250x",
        image: coinStack,
      },
      {
        name: "1xFillWarehouseToken",
        rarity: "common",
        description: "You got 1 fill warehouse token.",
        text: "1x",
        image: fillWarehouseTokenIcon,
      },
      {
        name: "1xFounder",
        rarity: "uncommon",
        description: "You got 1 x Founder.",
        text: "1x",
        image: founderIcon,
      },
      {
        name: "1x24HoursResourceBoost",
        rarity: "uncommon",
        description: "You got 1 x 24 hours resource boost.",
        text: "1x",
        image: resourceBoostTokenIcon,
      },
      {
        name: "1x24HoursTroopTrainingBoost",
        rarity: "uncommon",
        description: "You got 1 24 hours troop training boost.",
        text: "1x",
        image: troopTrainingBoostTokenIcon,
      },
      {
        name: "2xRerollToken",
        rarity: "uncommon",
        description: "You got 2 chest reroll tokens.",
        text: "2x",
        image: rerollTokenIcon,
      },
      {
        name: "2xFillWarehouse",
        rarity: "uncommon",
        description: "You got 2x fill warehouse tokens.",
        text: "2x",
        image: fillWarehouseTokenIcon,
      },
      {
        name: "2xFounders",
        rarity: "rare",
        description: "You got 2x Founders.",
        text: "2x",
        image: founderIcon,
      },
      {
        name: "1000xCoins",
        rarity: "rare",
        description: "You got 1000 coins.",
        text: "1000x",
        image: coinStack,
      },
      {
        name: "1xRaiderToken",
        rarity: "rare",
        description: "Lets you bypass the defenders troops during one attack",
        text: "1x",
        image: raiderTokenIcon,
      },
      {
        name: "1xPuzzlePiece",
        rarity: "rare",
        description: "1/5 pieces of a puzzle, what could it be?",
        text: "1x",
        image: puzzlePiecesIcon,
      },
      {
        name: "1x+1Queue24Hours",
        rarity: "very rare",
        description: "One extra building in queue for 24 hours token.",
        text: "1x",
        image: extraBuildingQueueTokenIcon,
      },
      {
        name: "2xUpgradeToken",
        rarity: "very rare",
        description: "You got 2 instant upgrade tokens.",
        text: "2x",
        image: freeBuildingUpgradeTokenIcon,
      },
      {
        name: "3xRerollToken",
        rarity: "very rare",
        description: "You got 3 chest reroll tokens.",
        text: "3x",
        image: rerollTokenIcon,
      },
      {
        name: "2x+1Queue24Hours",
        rarity: "very rare",
        description: "2x One extra building in queue for 24 hours tokens.",
        text: "2x",
        image: extraBuildingQueueTokenIcon,
      },
      {
        name: "3xFillWarehouse",
        rarity: "epic",
        description: "You got 3 fill warehouse tokens.",
        text: "3x",
        image: fillWarehouseTokenIcon,
      },
      {
        name: "3xFounders",
        rarity: "epic",
        description: "You got 3 Founders.",
        text: "3x",
        image: founderIcon,
      },
      {
        name: "1xCaligula'sPalace",
        rarity: "legendary",
        description: "You got 1 Caligula's Palace.",
        text: "1x",
        image: CalugulasPalaceIcon,
      },
    ];

    let resPoolOrdered = [];
    if (version === "v1") {
      resPoolOrdered = resPoolV1Ordered;
    } else if (version === "v2") {
      resPoolOrdered = resPoolV2Ordered;
    }

    for (let i = resPoolOrdered.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      let temp = resPoolOrdered[i];
      resPoolOrdered[i] = resPoolOrdered[j];
      resPoolOrdered[j] = temp;
    }

    return resPoolOrdered;
  },
  setLootboxImageByType(item) {
    switch (item.name) {
      case "3xCoins":
        item.image = coinStack;
        break;
      case "500xEachRes":
        item.image = resGroup;
        break;
      case "5xCoins":
        item.image = coinStack;
        break;
      case "2500xEachRes":
        item.image = resGroup;
        break;
      case "10xAxemen":
        item.image = axeManIcon;
        break;
      case "5000xWheat":
        item.image = wheatIcon;
        break;
      case "5000xWood":
        item.image = woodIcon;
        break;
      case "5000xStone":
        item.image = stoneIcon;
        break;
      case "5000xIron":
        item.image = ironIcon;
        break;
      case "20xCoins":
        item.image = coinStack;
        break;
      case "50xAxemen":
        item.image = axeManIcon;
        break;
      case "10000xEachRes":
        item.image = resGroup;
        break;
      case "50xCoins":
        item.image = coinStack;
        break;
      case "50000xWheat":
        item.image = wheatIcon;
        break;
      case "50000xWood":
        item.image = woodIcon;
        break;
      case "50000xStone":
        item.image = stoneIcon;
        break;
      case "50000xIron":
        item.image = ironIcon;
        break;
      case "250xAxemen":
        item.image = axeManIcon;
        break;
      case "500xClubmen":
        item.image = clubmanIcon;
        break;
      case "1000xCoins":
        item.image = coinStack;
        break;
      case "1xUpgradeToken":
        item.image = freeBuildingUpgradeTokenIcon;
        break;
      case "1xRerollToken":
        item.image = rerollTokenIcon;
        break;
      case "250xCoins":
        item.image = coinStack;
        break;
      case "1xFillWarehouseToken":
        item.image = fillWarehouseTokenIcon;
        break;
      case "1xFounder":
        item.image = founderIcon;
        break;
      case "1x24HoursResourceBoost":
        item.image = resourceBoostTokenIcon;
        break;
      case "1x24HoursTroopTrainingBoost":
        item.image = troopTrainingBoostTokenIcon;
        break;
      case "2xRerollToken":
        item.image = rerollTokenIcon;
        break;
      case "2xFillWarehouse":
        item.image = fillWarehouseTokenIcon;
        break;
      case "2xFounders":
        item.image = founderIcon;
        break;
      case "1000xCoins":
        item.image = coinStack;
        break;
      case "1xRaiderToken":
        item.image = raiderTokenIcon;
        break;
      case "1xPuzzlePiece":
        item.image = puzzlePiecesIcon;
        break;
      case "1x+1Queue24Hours":
        item.image = extraBuildingQueueTokenIcon;
        break;
      case "2xUpgradeToken":
        item.image = freeBuildingUpgradeTokenIcon;
        break;
      case "3xRerollToken":
        item.image = rerollTokenIcon;
        break;
      case "2x+1Queue24Hours":
        item.image = extraBuildingQueueTokenIcon;
        break;
      case "3xFillWarehouse":
        item.image = fillWarehouseTokenIcon;
        break;
      case "3xFounders":
        item.image = founderIcon;
        break;
      case "1xCaligula'sPalace":
        item.image = CalugulasPalaceIcon;
        break;
      default:
        item.image = "FAILED";
    }
    return item;
  },
  getRandomItemFromArray(version) {
    const pool = this.getShuffledItemPoolArray(version);
    const newarray = [];
    newarray.push(pool[0]);
    newarray.push(pool[1]);
    return newarray;
  },
  turnNumberToNumberic(num) {
    if (num >= 1_000_000_000_000)
      return (num / 1_000_000_000_000).toFixed(1) + "T";
    if (num >= 10000_000_000) return (num / 1_000_000_000).toFixed(2) + "B";
    if (num >= 1000000) return (num / 1_000_000).toFixed(2) + "M";
    if (num >= 100000) return (num / 1_000).toFixed(1) + "K";
    return num.toString();
  },
  getCanBuildTimestamp(
    current_wheat,
    current_wood,
    current_stone,
    current_iron,
    wheat_per_min,
    wood_per_min,
    stone_per_min,
    iron_per_min,
    wheat_price,
    wood_price,
    stone_price,
    iron_price,
    warehouse_capacity
  ) {
    if (
      warehouse_capacity < wheat_price ||
      warehouse_capacity < wood_price ||
      warehouse_capacity < stone_price ||
      warehouse_capacity < iron_price
    ) {
      return "Never";
    }

    const getMinutesNeeded = (current, ratePerMin, required) => {
      if (current >= required) return 0;
      if (ratePerMin <= 0) return Infinity;
      return (required - current) / ratePerMin;
    };

    const wheatTime = getMinutesNeeded(
      current_wheat,
      wheat_per_min,
      wheat_price
    );

    const woodTime = getMinutesNeeded(current_wood, wood_per_min, wood_price);
    const stoneTime = getMinutesNeeded(
      current_stone,
      stone_per_min,
      stone_price
    );
    const ironTime = getMinutesNeeded(current_iron, iron_per_min, iron_price);

    const maxTime = Math.max(wheatTime, woodTime, stoneTime, ironTime);

    if (maxTime === Infinity) {
      return "Never";
    }

    const now = new Date();
    const affordableAt = new Date(now.getTime() + maxTime * 60000);

    const day = affordableAt.getDate().toString().padStart(2, "0");
    const month = (affordableAt.getMonth() + 1).toString().padStart(2, "0");
    const year = affordableAt.getFullYear();
    const hours = affordableAt.getHours().toString().padStart(2, "0");
    const minutes = affordableAt.getMinutes().toString().padStart(2, "0");

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  },
  getResourceFullTimestamp(res_per_min, current_res, max_res) {
    const res_per_min_per_sec = res_per_min / 60;
    const current_res_per_sec = current_res / 60;
    const max_res_per_sec = max_res / 60;

    const time_left = Math.floor(
      (max_res_per_sec - current_res_per_sec) / res_per_min_per_sec
    );

    if (time_left <= 0) {
      return "Full";
    }

    const now = new Date();
    const affordableAt = new Date(now.getTime() + time_left * 60000);

    const day = affordableAt.getDate().toString().padStart(2, "0");
    const month = (affordableAt.getMonth() + 1).toString().padStart(2, "0");
    const year = affordableAt.getFullYear();
    const hours = affordableAt.getHours().toString().padStart(2, "0");
    const minutes = affordableAt.getMinutes().toString().padStart(2, "0");

    return `Full at ${day}.${month}.${year} ${hours}:${minutes}`;
  },
};

export function getRarityColor(rarity) {
  switch (rarity) {
    case "common":
      return "bg-gray-400";
    case "uncommon":
      return "bg-green-500";
    case "rare":
      return "bg-blue-500";
    case "very rare":
      return "bg-purple-500";
    case "epic":
      return "bg-pink-500";
    case "legendary":
      return "bg-yellow-500";
    default:
      return "bg-gray-400"; // default color
  }
}
