import { useEffect, useState } from "react";
import { HelperFunctions } from "../../HelperFunctions";
import { useTimerState } from "../contexts/timerContext";

export function BuildingTimerList() {
  const globalTimers = useTimerState();
  const [buildingTimers, setBuildingTimers] = useState([]);
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    const combinedTimers = [
      ...globalTimers.resourceTimers,
      ...globalTimers.buildingTimers,
    ];
    combinedTimers.sort((a, b) => a.endTime - b.endTime);
    setBuildingTimers(combinedTimers);
  }, [globalTimers]);

  if (buildingTimers.length === 0) return null;

  const isSingleItem = buildingTimers.length === 1;

  return (
    <div className="flex flex-col items-center text-white px-2 sm:px-0">
      {/* Chevron button above box */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full max-w-[800px] flex items-center justify-center bg-mainDarkBrown text-black py-1 rounded-t-lg border-x border-t border-black"
        aria-label="Toggle building timer list"
      >
        <span
          className={`block w-2 h-2 border-t-2 border-r-2 border-black transform ${
            isOpen ? "rotate-[-45deg]" : "rotate-[133deg]"
          } transition-transform duration-300`}
        />
      </button>

      {/* The content box */}
      <div
        className={`w-full max-w-[800px] bg-mainLightYellow rounded-b-lg border-x border-b border-black shadow-md p-2 transition-all duration-300 ease-in-out overflow-hidden ${
          isOpen ? "max-h-[1000px] opacity-100" : "max-h-0 opacity-0"
        }`}
      >
        <ul
          className={`grid ${
            isSingleItem ? "grid-cols-1" : "xxxs:grid-cols-2"
          } gap-4 divide-y divide-gray-200`}
        >
          {buildingTimers.map((timer, index) => (
            <li
              key={index}
              className={`p-2 border-2 border-black bg-mainDarkBrown`}
            >
              <div className="flex items-center">
                <h4 className="font-semibold text-xs sm:text-sm md:text-base lg:text-lg mr-2">
                  {index + 1}.
                </h4>
                <h3 className="font-semibold text-xs sm:text-sm md:text-base lg:text-base mr-2">
                  {timer.extra.building_name} to {timer.extra.next_level}
                </h3>
              </div>
              <div className="text-sm font-semibold mb-1">
                <span className="font-semibold text-xs mx-auto">
                  {index === 0
                    ? `${HelperFunctions.secondsToTimestamp(timer.timeLeft)}`
                    : `Waiting... ${HelperFunctions.secondsToTimestamp(
                        timer.duration
                      )}`}
                </span>
              </div>
              {index === 0 && (
                <div className="relative pt-1">
                  <div className="overflow-hidden h-2 text-xs flex rounded bg-blue-200">
                    <div
                      style={{
                        width: `${
                          ((timer.duration - timer.timeLeft) / timer.duration) *
                          100
                        }%`,
                      }}
                      className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500 transition-all duration-1000"
                    />
                  </div>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
