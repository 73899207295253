import { React, useContext, useState } from "react";
import { SignedOutsNavBar } from "../Components/bars/SignedOutsNavBar";
import { SignedOutFooter } from "../Components/bars/SignedOutFooter";
import { AuthContext, UserContext } from "../index";
import { GameOverHeader } from "../Components/bars/GameOverHeader";
import { FixedLayout } from "../FixedLayout";
import { toast } from "react-toastify";

export function BugReportPage() {
  const { authToken } = useContext(AuthContext);
  const { gameOver } = useContext(UserContext);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    description: "",
    steps: "",
    severity: "Low",
    from: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission logic here (e.g., send data to a server)
    await fetch(`${import.meta.env.VITE_GAMEHOST}/api/bug/report`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(formData),
    });
    setFormData({
      name: "",
      description: "",
      steps: "",
      severity: "Low",
      from: "",
    });
    toast.success("Bug report sent!");
  };

  return (
    <div className="w-screen h-screen bg-gradient-to-b from-mainLightGreen to-[#d4a373] flex flex-col ">
      {!authToken ? (
        <SignedOutsNavBar />
      ) : gameOver ? (
        <GameOverHeader />
      ) : (
        <FixedLayout />
      )}

      <div className="flex flex-col items-center justify-center mb-auto  my-auto ">
        <div className=" w-[90vw]  md:w-1/2   p-6 bg-mainAttack rounded-lg shadow-md overflow-auto max-h-[70vh] mt-4">
          <h2 className="text-2xl font-bold mb-4">Report a Bug</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                required
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700"
              >
                Bug Description
              </label>
              <textarea
                id="description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                rows="4"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="steps"
                className="block text-sm font-medium text-gray-700"
              >
                Steps to Reproduce
              </label>
              <textarea
                id="steps"
                name="steps"
                value={formData.steps}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                rows="4"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="severity"
                className="block text-sm font-medium text-gray-700"
              >
                Severity
              </label>
              <select
                id="severity"
                name="severity"
                value={formData.severity}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                required
              >
                <option value="Low">Low</option>
                <option value="Medium">Medium</option>
                <option value="High">High</option>
                <option value="Critical">Critical</option>
              </select>
            </div>

            <div className="mb-4">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Submitted by
              </label>
              <input
                type="text"
                id="from"
                name="from"
                value={formData.from}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                required
              />
            </div>

            <div className="flex justify-end">
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      {!authToken && <SignedOutFooter />}
    </div>
  );
}
