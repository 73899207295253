import { useContext, useEffect, useState } from "react";
import {
  CasinoContext,
  CityConfigContext,
  UserContext,
  WarehouseContext,
} from "../../index.jsx";
import { useNavigate } from "react-router-dom";
import { BUILDING_MENU_DESCRPIPTIONS } from "../../constants";
import { SmallWheatIcon } from "../../images/resources/SmallWheatIcon";
import { SmallWoodIcon } from "../../images/resources/SmallWoodIcon";
import { SmallStoneIcon } from "../../images/resources/SmallStoneIcon";
import { SmallIronIcon } from "../../images/resources/SmallIronIcon";
import { timerActions } from "../reducers/timerReducer";
import { useTimerDispatch, useTimerState } from "../contexts/timerContext";
import { BuildingInfoImage } from "../../images/BuildingInfoImage";
import { HelperFunctions } from "../../HelperFunctions";
import { InstaBuildingCooldown } from "../InstaBuildingCooldown.jsx";
import { RULE_CONFIG } from "../../RULES.js";
import { toast } from "react-toastify";
import { CasinoCoinResponsive } from "../../images/CasinoCoinResponsive.jsx";
import { InstaBuildTokenButton } from "../InstaBuildTokenButton.jsx";
import { AllBuildingOptions } from "../AllBuildingOptions.jsx";
import { JadeIconResponsive } from "../../images/resources/JadeIconResponsive.jsx";

export function BuildingMenuItem(prop) {
  const { insta_builds_left, cityTokens } = useContext(CityConfigContext);
  const { currentCasinoCoins } = useContext(CasinoContext);

  const dispatch = useTimerDispatch();
  const [hasEnoughResources, setHasEnoughResources] = useState(false);

  const nav = useNavigate();

  const { warehouse } = useContext(WarehouseContext);
  const { inCityId, cityBonus } = useContext(CityConfigContext);

  const allTimers = useTimerState();
  const [canInstaBuild, setCanInstaBuild] = useState(false);

  const [buildingTimers, setBuildingTimers] = useState([
    ...allTimers.resourceTimers,
    ...allTimers.buildingTimers,
  ]);
  const { setShowSpinner } = useContext(UserContext);

  useEffect(() => {
    setBuildingTimers([
      ...allTimers.resourceTimers,
      ...allTimers.buildingTimers,
    ]);
  }, [allTimers]);

  function getBuildingDescription() {
    if (prop.building.building_name === "Warehouse") {
      return BUILDING_MENU_DESCRPIPTIONS.Warehouse;
    } else if (prop.building.building_name === "Barracks") {
      return BUILDING_MENU_DESCRPIPTIONS.Barracks;
    } else if (prop.building.building_name === "Mapchamber") {
      return BUILDING_MENU_DESCRPIPTIONS.Mapchamber;
    } else if (prop.building.building_name === "Embassy") {
      return BUILDING_MENU_DESCRPIPTIONS.Embassy;
    } else if (prop.building.building_name === "Casino") {
      return BUILDING_MENU_DESCRPIPTIONS.Casino;
    } else if (prop.building.building_name === "Marketplace") {
      return BUILDING_MENU_DESCRPIPTIONS.Marketplace;
    } else if (prop.building.building_name === "Mill") {
      return BUILDING_MENU_DESCRPIPTIONS.Mill;
    } else if (prop.building.building_name === "Lumberyard") {
      return BUILDING_MENU_DESCRPIPTIONS.Lumberyard;
    } else if (prop.building.building_name === "Quarry") {
      return BUILDING_MENU_DESCRPIPTIONS.Quarry;
    } else if (prop.building.building_name === "Smith") {
      return BUILDING_MENU_DESCRPIPTIONS.Smith;
    } else if (prop.building.building_name === "Brothel") {
      return BUILDING_MENU_DESCRPIPTIONS.Brothel;
    } else if (prop.building.building_name === "Church") {
      return BUILDING_MENU_DESCRPIPTIONS.Church;
    } else if (prop.building.building_name === "Caligula's Palace") {
      return BUILDING_MENU_DESCRPIPTIONS.CaligulasPalace;
    } else if (prop.building.building_name === "Artifex Foundry") {
      return BUILDING_MENU_DESCRPIPTIONS.Foundry;
    } else if (prop.building.building_name === "Victory Monument") {
      return BUILDING_MENU_DESCRPIPTIONS.VictoryMonument;
    }
  }

  useEffect(() => {
    if (prop.building.building_name === "Caligula's Palace") {
      setCanInstaBuild(true);
      if (warehouse.wheat >= prop.building.wheat_req) {
        setHasEnoughResources(true);
      } else {
        setHasEnoughResources(false);
      }
    } else if (prop.building.building_name === "Victory Monument") {
      setCanInstaBuild(false);
      if (warehouse.jade >= prop.building.wheat_req) {
        setHasEnoughResources(true);
      } else {
        setHasEnoughResources(false);
      }
    } else {
      setCanInstaBuild(true);
      if (
        warehouse.wheat >= prop.building.wheat_req &&
        warehouse.wood >= prop.building.wood_req &&
        warehouse.stone >= prop.building.stone_req &&
        warehouse.iron >= prop.building.iron_req
      ) {
        setHasEnoughResources(true);
      } else {
        setHasEnoughResources(false);
      }
    }
  }, [warehouse]);

  if (!prop.building) {
    return <h2>Loading..</h2>;
  }

  const build = async (buildingId, next_level, insta_complete) => {
    setShowSpinner(true);
    const res = await fetch(
      `${import.meta.env.VITE_GAMEHOST}/api/building/build/${
        prop.building.building_name
      }/${prop.buildingSpot}/${inCityId}?insta_complete=${insta_complete}`,
      {
        credentials: "include",
      }
    );

    if (res.status === 200) {
      nav("/town");

      const data = await res.json();
      // clear all timers and
      // TODO should add an ID for this object also

      dispatch({
        type: timerActions.ADD_TIMER,
        category: "buildingTimers",
        payload: {
          duration: data.building_total_time,
          location: data.location,
          timeLeft: data.timeLeft,
          endTime: data.end_time,
          cityId: data.cityId,
          extra: {
            building_name: data.building_name,
            next_level: data.building_level,
          },
        },
      });
      if (cityBonus && cityBonus.insta_building_complete_chance > 0) {
        toast(`YOU ROLLED ${data.roll}`);
      }
    } else if (res.status === 201) {
      const data = await res.json();
      if (cityBonus && cityBonus.insta_building_complete_chance > 0) {
        toast(`YOU ROLLED ${data.roll}, building insta completed!`);
      } else {
        toast("Building insta completed!");
      }
      nav("/town");
    }
    setTimeout(() => {
      setShowSpinner(false);
    }, 1700);
  };

  return (
    <div className="bg-mainLightBrown1 rounded-lg shadow-md p-4 text-black flex flex-col hover:shadow-lg transition-all duration-200 ease-in-out">
      <div className="flex-grow">
        <h2 className="text-base xs:text-xl font-fantasy font-bold mb-4 text-center text-gray-800">
          {prop.building.building_name}
        </h2>
        <BuildingInfoImage type={prop.building.building_name} />
        {/*          <img
              src={getImage()}
              alt={prop.building.building_name}
              className="w-full h-40 object-contain mb-4 rounded"
          />*/}
        <div>
          <div className="text-xs md:text-base">{getBuildingDescription()}</div>
          <h3 className="text-sm md:text-lg font-fantasy font-semibold mb-2 flex justify-center">
            Requirement:
          </h3>

          <div className="flex justify-center">
            {prop.building.building_name !== "Caligula's Palace" ? (
              <div>
                {prop.building.building_name !== "Victory Monument" ? (
                  <>
                    <div className="grid grid-cols-2 xl:grid-cols-4 gap-2 place-items-center  w-full text-xxs sm:text-xs md:text-sm">
                      <div>
                        <SmallWheatIcon />
                        <div className="ml-2">
                          {HelperFunctions.turnNumberToNumberic(
                            prop.building.wheat_req
                          )}
                        </div>
                      </div>
                      <div>
                        <SmallWoodIcon />{" "}
                        <div className="ml-2">
                          {HelperFunctions.turnNumberToNumberic(
                            prop.building.wood_req
                          )}
                        </div>
                      </div>
                      <div>
                        <SmallStoneIcon />{" "}
                        <div className="ml-2">
                          {HelperFunctions.turnNumberToNumberic(
                            prop.building.stone_req
                          )}
                        </div>
                      </div>
                      <div>
                        <SmallIronIcon />{" "}
                        <div className="ml-2">
                          {HelperFunctions.turnNumberToNumberic(
                            prop.building.iron_req
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="flex">
                    <JadeIconResponsive />{" "}
                    <div className="ml-2">
                      {HelperFunctions.turnNumberToNumberic(
                        prop.building.wheat_req
                      )}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="flex justify-center">
                <p className="mr-2">
                  {HelperFunctions.turnNumberToNumberic(
                    prop.building.wheat_req
                  )}
                </p>
                <CasinoCoinResponsive />
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-center">
          {HelperFunctions.secondsToTimestamp(prop.building.seconds_to_build)}
        </div>
      </div>
      <div className="mt-4">
        {prop.building.building_name !== "Caligula's Palace" ? (
          <div>
            <AllBuildingOptions
              bonuses={cityBonus}
              build={build}
              buildingTimers={buildingTimers}
              hasEnoughResources={hasEnoughResources}
              buildingId={prop.building.buildingId}
              canInstaBuild={canInstaBuild}
              wheat_cost={prop.building.wheat_req}
              wood_cost={prop.building.wood_req}
              stone_cost={prop.building.stone_req}
              iron_cost={prop.building.iron_req}
            />
          </div>
        ) : (
          <div>
            {cityBonus &&
            buildingTimers.length >=
              RULE_CONFIG.default_buildings_in_queue +
                cityBonus.extra_queue_buildings ? (
              <h4 className="text-yellow-500">Building queue is full</h4>
            ) : (
              <div>
                {prop.building.wheat_req > currentCasinoCoins ? (
                  <h4 className="text-red-600">Not enough coins</h4>
                ) : (
                  <div>
                    <div className="flex space-x-4">
                      <button
                        onClick={() => build(prop.building.buildingId, false)}
                        className="bg-mainYellow hover:bg-green-600 text-white font-bold py-2 px-4 rounded w-full"
                      >
                        Build
                      </button>

                      <InstaBuildingCooldown
                        builds_left={insta_builds_left}
                        build={() => () =>
                          build(prop.building.buildingId, true)}
                      />
                      <InstaBuildTokenButton
                        build={() => build(prop.building.buildingId, "token")}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
